import React from "react";
import { Icon } from "@aidron/aidron-ds";
import { Link } from "react-router-dom/cjs/react-router-dom";

import * as S from "./LabelAndNumberView.style";

import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";
import CustomHeading from "../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function LabelAndNumberView({ heading, number, text, numberText, link }) {
  function isValue() {
    return /\d/.test(number);
  }

  return (
    <S.Wrapper>
      <CustomText weight="bold" color="mediumGrey">
        {heading.toUpperCase()}
      </CustomText>
      {isValue() ? (
        <div className={numberText ? "d-flex align-items-end" : ""}>
          <CustomHeading size="lg" weight="bold" color="orange">
            {number}
          </CustomHeading>
          <CustomText color="orange" className={numberText ? "ms-2" : "d-none"}>
            {numberText ? numberText : ""}
          </CustomText>
        </div>
      ) : (
        <CustomText color="orange" weight="bold" minHeight>
          {number}
        </CustomText>
      )}
      {link ? (
        <Link to={link}>
          <S.LinkText>{text}</S.LinkText>
          <Icon icon="chevron-right" />
        </Link>
      ) : (
        <CustomText size="xs" margin="0">
          {text}
        </CustomText>
      )}
    </S.Wrapper>
  );
}
