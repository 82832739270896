import { capitalizeFirst, sumColumn } from "./utils";
import { i18n } from "../translate/i18n";

const { t } = i18n;

export const forecastColumns = () => {
  return [
    {
      label: t("partPercent"),
      name: "participationInAidron",
      fullLabel: t("percentParticipation"),
      type: "%",
      calculate: values => calculateParticipation(values),
    },
    {
      label: t("currentPlan"),
      name: "clientPrediction",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("ai"),
      name: "aidronPrediction",
      fullLabel: t("aiForecast"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("differenceAidronAndClient"),
      name: "differenceAidronAndClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif",
      calculate: values => calculateDiff(values),
      colorReferenceFrom: "percentAidronVsClient",
    },
    {
      label: t("percentAidronVsClient"),
      name: "percentAidronVsClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
  ];
};

export const consolidatedForecastColumns = () => {
  return [
    {
      label: t("currentPlan"),
      name: "clientPrediction",
      type: "prediction",
    },
    {
      label: t("ai"),
      name: "aidronPrediction",
      fullLabel: t("aiForecast"),
      type: "prediction",
    },
    {
      label: t("maxPot"),
      name: "confidenceIntervalMax",
      fullLabel: t("maxPotential"),
      type: "prediction",
    },
    {
      label: t("minPot"),
      name: "confidenceIntervalMin",
      fullLabel: t("minPotential"),
      type: "prediction",
    },
    {
      label: t("percentAidronVsClient"),
      name: "percentAidronVsClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif%",
    },
  ];
};

export const accuracyColumnsCurrentMonth = ({ companyName }) => {
  const allColumns = [
    {
      label: t("currentPlan"),
      name: "officialClientPrediction",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("ai"),
      name: "officialAidronPrediction",
      fullLabel: t("aiForecast"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("projection"),
      name: "projection",
      fullLabel: t("maxPotential"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("realized"),
      name: "realized",
      fullLabel: t("realizedSales"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("upperLimitAbbreviation"),
      name: "confidenceIntervalMax",
      fullLabel: t("upperLimit"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("lowerLimitAbbreviation"),
      name: "confidenceIntervalMin",
      fullLabel: t("lowerLimit"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("partPercent"),
      name: "participationInProjection",
      fullLabel: "Participação % na Projeção",
      type: "%",
      calculate: values => calculateParticipation(values),
    },
    {
      label: t("percentDiffAIVsProjection"),
      name: "percentOfficialAidronVsProjection",
      indexOne: "officialAidronPrediction",
      indexTwo: "projection",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
    {
      label: t("percentDiffCurrentPlanVsProjection"),
      name: "percentOfficialClientVsProjection",
      indexOne: "officialClientPrediction",
      indexTwo: "projection",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
  ];

  if (companyName === "Caedu") {
    return allColumns;
  } else {
    const columnsToRemove = ["projection", "percentOfficialAidronVsProjection", "percentOfficialClientVsProjection"];
    return allColumns.filter(column => !columnsToRemove.includes(column.name));
  }
};

export const accuracyColumnsPast = () => {
  return [
    {
      label: t("currentPlan"),
      name: "officialClientPrediction",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("ai"),
      name: "officialAidronPrediction",
      fullLabel: t("aiForecast"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("realized"),
      name: "realized",
      fullLabel: t("realizedSales"),
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: t("partPercent"),
      name: "participationInRealized",
      fullLabel: t("percentParticipationOnRealized"),
      type: "%",
      calculate: values => calculateParticipation(values),
    },
    {
      label: t("percentDifferenceAIVsRealized"),
      name: "percentOfficialAidronVsRealized",
      indexOne: "officialAidronPrediction",
      indexTwo: "realized",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
    {
      label: t("percentDifferenceCurrentPlanVsRealized"),
      name: "percentOfficialClientVsRealized",
      indexOne: "officialClientPrediction",
      indexTwo: "realized",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
  ];
};

export const historicColumns = ({ unit }) => {
  return [
    {
      label: t("realized"),
      name: unit ? `realized${capitalizeFirst(unit)}` : "realized",
      fullLabel: t("realizedSales"),
      type: "prediction",
    },
    {
      label: t("partPercent"),
      name: unit ? `participationInRealized${capitalizeFirst(unit)}` : "realized",
      fullLabel: t("percentParticipationInRealized"),
      type: "%",
    },
  ];
};

export const storesFutureColumns = ({ predictionByUnit }) => {
  return [
    {
      label: "Previsto",
      name: predictionByUnit,
      type: "prediction",
    },
  ];
};

export const storesConsolidatedColumns = () => {
  return [
    {
      label: t("currentPlan"),
      name: "clientPrediction",
      type: "prediction",
    },
    {
      label: t("ai"),
      name: "aidronPrediction",
      fullLabel: t("aiForecast"),
      type: "prediction",
    },
    {
      label: t("maxPot"),
      name: "confidenceIntervalMax",
      fullLabel: t("maxPotential"),
      type: "prediction",
    },
    {
      label: t("minPot"),
      name: "confidenceIntervalMin",
      fullLabel: t("minPotential"),
      type: "prediction",
    },
    {
      label: t("percentDifferenceAIVsStorePlan"),
      name: "percentAidronVsClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif%",
    },
  ];
};

export const channelAccuracyColumns = ({ unit }) => {
  const unitSuffix = capitalizeFirst(unit);
  return [
    {
      label: t("ai"),
      name: `officialPrediction${unitSuffix}`,
      fullLabel: t("aiForecast"),
      type: "prediction",
    },
    {
      label: t("realized"),
      name: `realized${unitSuffix}`,
      fullLabel: t("realizedSales"),
      type: "prediction",
    },
    {
      label: t("partPercent"),
      name: `realized${unitSuffix}`,
      fullLabel: t("percentParticipationOnRealized"),
      type: "%",
    },
    {
      label: t("percentDifferenceAIVsRealized"),
      name: `percentOfficialVsRealized${unitSuffix}`,
      indexOne: `officialPrediction${unitSuffix}`,
      indexTwo: `realized${unitSuffix}`,
      type: "dif%",
    },
  ];
};

export const channelAccuracyProductColumns = ({ unit }) => {
  const unitSuffix = capitalizeFirst(unit);
  return [
    {
      label: t("ai"),
      name: `sumOfficialPrediction${unitSuffix}`,
      fullLabel: t("aiForecast"),
      type: "prediction",
    },
    {
      label: t("realized"),
      name: `sumRealized${unitSuffix}`,
      fullLabel: t("realizedSales"),
      type: "prediction",
    },
    {
      label: t("partPercent"),
      name: `sumParticipationInRealized${unitSuffix}`,
      fullLabel: t("percentParticipationOnRealized"),
      type: "%",
    },
    {
      label: t("percentDifferenceAIVsRealized"),
      name: `percentOfficialVsRealized${unitSuffix}`,
      indexOne: `officialPrediction${unitSuffix}`,
      indexTwo: `realized${unitSuffix}`,
      type: "dif%",
    },
  ];
};

export const adjustPlanColumns = [
  {
    label: t("currentPlan"),
    name: "clientPrediction",
    type: "prediction",
    color: () => "lightGrey",
    calculate: values => sumColumn(values.selectedPredictions, values.name),
  },
  {
    label: t("aiAdjustment"),
    name: "opportunityAdjustment",
    type: "prediction",
    color: value => (value < 0 ? "red" : "blue"),
    calculate: values => sumColumn(values.selectedPredictions, values.name),
  },
  {
    label: t("newPlan"),
    name: "newPlan",
    indexOne: "clientPrediction",
    indexTwo: "opportunityAdjustment",
    type: "dif",
    color: () => "black",
    calculate: values => sumNewPlan(values),
  },
];

function calculateParticipation(values) {
  const allPredictions = sumColumn(values.allPredictions, values.name);
  const selectedPredictions = sumColumn(values.selectedPredictions, values.name);
  return (selectedPredictions / allPredictions) * 100;
}

function calculateDiff(values) {
  const sumIndexOne = sumColumn(values.selectedPredictions, values.indexOne);
  const sumIndexTwo = sumColumn(values.selectedPredictions, values.indexTwo);
  return sumIndexOne - sumIndexTwo;
}

function calculateDiffPercent(values) {
  const sumIndexOne = sumColumn(values.selectedPredictions, values.indexOne);
  const sumIndexTwo = sumColumn(values.selectedPredictions, values.indexTwo);

  if (sumIndexOne && sumIndexTwo) {
    return ((sumIndexOne - sumIndexTwo) / sumIndexTwo) * 100;
  } else {
    return null;
  }
}

function sumNewPlan(values) {
  return values.selectedPredictions.reduce((total, currentObject) => {
    const columnName = currentObject.editedNewPlan ? "editedNewPlan" : "newPlan";
    if (currentObject[columnName]) return Number(total) + Number(currentObject[columnName]);
    else return total;
  }, 0);
}
