import React, { memo, useState, useContext } from "react";
import { Button, Card, Heading, Text } from "@aidron/aidron-ds";

import { CompanyContext } from "../../../templates/context/CompanyContext";
import { i18n } from "../../../translate/i18n";

import { createFile, saveFile } from "../../../services/FilesService";
import { getSettings } from "../../../services/SettingsService";

import ModalTemplate from "../../../components/ModalTemplate/ModalTemplate";
import ChangePassword from "./ChangePassword/ChangePassword";

export default function MyAccountSettingsTab() {
  const { t } = i18n;

  const { userInfo, setUserInfo } = useContext(CompanyContext);
  const [uploadedFile, setUploadedFile] = useState("");
  const [showSavedPhotoModal, setShowSavedPhotoModal] = useState(false);

  function handleUpload(e) {
    setUploadedFile(e.target.files[0]);
  }

  async function handleFileChange() {
    try {
      let formData = new FormData();
      formData.append("file", uploadedFile);

      const newFile = {
        originalName: uploadedFile.name,
        content: formData.file,
        encoding: "binary",
        mimeType: uploadedFile.type,
      };

      const hasAvatar = typeof userInfo.avatarId === "number" ? true : false;

      const token = localStorage.getItem("token");

      if (hasAvatar) {
        await saveFile(newFile, token);
      } else {
        await createFile(newFile, token);
      }

      getSettings(token).then(res => setUserInfo(res));

      setShowSavedPhotoModal(true);
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  }

  return (
    <>
      <Card style={{ margin: "20px 0 10px 0", padding: "20px 20px 20px 30px" }}>
        <div className="mb-4">
          <ChangePassword />
          {/* <div className="mt-5">
            <Heading size="sm">Foto do perfil</Heading>
            <div className="d-flex mt-3">
              <label htmlFor="file-upload" className="aidron-ds-button-styles">
                Escolher arquivo
              </label>
              <input
                id="file-upload"
                type="file"
                name="file"
                accept="image/*"
                onChange={handleUpload}
                className="d-none"
                key={uploadedFile}
              />
            </div>
            {uploadedFile ? (
              <div className="d-flex">
                <Text className="me-3 green-color">Arquivo carregado!</Text>
                {uploadedFile && (
              <img
                src={URL.createObjectURL(uploadedFile)}
                width="100"
                height="100"
              />
            )}
                <Button label="Salvar" onClick={handleFileChange} />
              </div>
            ) : (
              <></>
            )}
          </div> */}
        </div>
      </Card>
      {showSavedPhotoModal && (
        <ModalTemplate width="500px" heading="Foto salva com sucesso!" closingFunction={() => setShowSavedPhotoModal(false)}>
          <Text>{t("newPhotoSaved")}</Text>
        </ModalTemplate>
      )}
    </>
  );
}
