import React, { useState, useContext, useEffect } from "react";
import { Card } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";

import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getForecasts } from "../../../services/ForecastService";
import { getOpportunitiesCount } from "../../../services/OpportunityService";

import * as S from "./CheckoutOuterCard.style";

import OuterCardHeading from "../OuterCardHeading/OuterCardHeading";
import InnerCheckoutCard from "./InnerCheckoutCard/InnerCheckoutCard";

export default function CheckoutOuterCard() {
  const { t } = i18n;
  const { userInfo } = useContext(CompanyContext);
  const companyId = userInfo.company.id;

  const [counter, setCounter] = useState({});

  useEffect(() => {
    fetchOpportunities();
  }, []);

  async function fetchOpportunities() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "opportunities");
    const latestForecastId = forecasts[forecasts.length - 1].forecastId;

    const status = ["checkout"];
    const result = await getOpportunitiesCount(token, latestForecastId, status);
    setCounter(result);
  }

  function getCount(types) {
    const count = counter.counter?.reduce((acc, curr) => {
      if (types.includes(curr.type)) {
        return acc + curr.count;
      } else return acc;
    }, 0);
    return count ? count : 0;
  }

  return (
    <S.OuterWrapper>
      <Card style={{ height: "100%" }}>
        <OuterCardHeading title={t("actionsCheckout")} text={t("actionsCheckoutSubtitle")} />
        <S.InnerWrapper>
          <InnerCheckoutCard
            heading={t("salesOpportunities")}
            number={companyId === 4 ? 12 : getCount(["additionalSale", "avoidLoss"])}
            userEventName="Vendas"
          />
          <InnerCheckoutCard
            heading={t("buyOptimization")}
            number={companyId === 4 ? 8 : getCount(["stock"])}
            userEventName="Compra"
          />
          <InnerCheckoutCard
            heading={t("storesOpportunities")}
            number={companyId === 4 ? 42 : t("comingSoon")}
            userEventName="Lojas"
          />
        </S.InnerWrapper>
      </Card>
    </S.OuterWrapper>
  );
}
