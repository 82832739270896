import React, { useState, useEffect, useContext } from "react";

import { ForecastContext } from "../../../../../templates/context/ForecastContext";
import { getPredictionsByProduct } from "../../../../../services/PredictionsService";
import { i18n } from "../../../../../translate/i18n";

import ButtonWithModal from "../../../../../components/ButtonWithModal/ButtonWithModal";
import ProductAccuracyChart from "../../../../../components/ProductAccuracyChart/ProductAccuracyChart";

export default function AccuracyChartButton({ productId }) {
  const { t } = i18n;

  const { selectedForecast } = useContext(ForecastContext);
  const [productPredictions, setProductPredictions] = useState([]);

  const unit = "items";

  useEffect(() => {
    const token = localStorage.getItem("token");
    getPredictionsByProduct(token, [productId]).then(res => {
      setProductPredictions(res);
    });
  }, []);

  return (
    <ButtonWithModal
      label={t("accuracyAnalysis")}
      icon="arrow-up-right"
      type="outlined"
      modalHeading={t("accuracyAnalysis")}
      modalWidth="700px"
      renderModalContent={() => (
        <div className="mx-5">
          <ProductAccuracyChart
            productId={productId}
            unit={unit}
            currentMonth={selectedForecast}
            productPredictions={productPredictions}
          />
        </div>
      )}
    />
  );
}
