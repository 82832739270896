import React, { useContext } from "react";
import { ForecastContext } from "../../../../templates/context/ForecastContext";
import { toNDecimalsString } from "../../../../utils/utils";
import { i18n } from "../../../../translate/i18n";

import * as S from "./ProductIndexes.style";

import ProductIndexCard from "./ProductIndexCard/ProductIndexCard";
import IconButtonWithModal from "../../../../components/IconButtonWithModal/IconButtonWithModal";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";
import HelpModal from "./HelpModal/HelpModal";

export default function ProductIndexes({ predictions }) {
  const { t } = i18n;
  const { selectedForecast } = useContext(ForecastContext);

  const currentMonth = selectedForecast.month;
  const currentYear = selectedForecast.year;

  const currentMonthPrediction = predictions.find(
    prediction => prediction.month === currentMonth && prediction.year === currentYear
  );

  const indexes = [
    {
      label: t("realizedDeviationVsDemand"),
      name: "realizedVsDemandDeviation",
      text: t("realizedDeviationVsDemandDescription"),
      format: includeArrowAndPercent,
    },
    {
      label: t("lastMonthSaleVariation"),
      name: "lastMonthSaleVariation",
      text: t("lastMonthSaleVariationDescription"),
      format: includeArrowUpAndPercent,
    },
    {
      label: t("saleAnomalyProbability"),
      name: "saleAnomalyProbability",
      text: t("saleAnomalyProbabilityDescription"),
      format: includePercentSymbol,
    },
    {
      label: t("aiAccuracyOverClient"),
      name: "aiAccuracyOverClient",
      text: t("aiAccuracyOverClientDescription"),
      format: includePlusOrMinusAndPercent,
    },
  ];

  const indexesWithValues = indexes.map(index => {
    const value = currentMonthPrediction?.[index.name];
    const formattedValue = index.format && value ? index.format(value) : value;
    return { ...index, value: formattedValue };
  });

  function includeArrowUpAndPercent(value) {
    if (value >= 100) {
      return `↑ 100%`;
    } else {
      return includePercentSymbol(value);
    }
  }

  function includeArrowAndPercent(value) {
    if (value == 0 || (value >= -0.04 && value <= 0.04)) {
      return "0%";
    } else {
      const arrow = value > 0 ? "↑" : "↓";
      return `${arrow} ${includePercentSymbol(value)}`;
    }
  }

  function includePlusOrMinusAndPercent(value) {
    if (value == 0 || (value >= -0.04 && value <= 0.04)) {
      return "0%";
    } else {
      const plusOrMinus = value > 0 ? "+" : "-";
      return `${plusOrMinus} ${includePercentSymbol(Math.abs(value))}`;
    }
  }

  function includePercentSymbol(value) {
    return `${toNDecimalsString(value, 1)}%`;
  }

  return (
    <S.OuterContainer>
      <S.ScrollContainer>
        <S.HeadingWrapper>
          <CustomText weight="bold" display="inline">
            {t("indexesToSupport").toUpperCase()}
          </CustomText>
          <IconButtonWithModal
            icon="help-circle"
            modalHeading={`${t("help")}: ${t("indexesToSupport").toLowerCase()}`}
            renderModalContent={() => <HelpModal indexes={indexes} />}
          />
        </S.HeadingWrapper>

        <S.CardsContainer>
          <S.CardsInnerContainer>
            {indexesWithValues.map((index, i) => (
              <ProductIndexCard key={i} index={index} />
            ))}
          </S.CardsInnerContainer>
        </S.CardsContainer>
      </S.ScrollContainer>
    </S.OuterContainer>
  );
}
