import React from "react";
import { Text } from "@aidron/aidron-ds";

import { getAllPredictionsFromMonth } from "../../../../../utils/utils";
import { forecastColumns } from "../../../../../utils/tableColumns";
import { i18n } from "../../../../../translate/i18n";

import BodyCells from "../../../../../components/BodyCells/BodyCells";
import CheckCell from "../../../../../components/CheckCell/CheckCell";
import HistoricBodyCells from "../../../../../components/HistoricBodyCells/HistoricBodyCells";

export default function PredictionsSubtotalRow({
  freezeColumns,
  checkedProducts,
  setCheckedProducts,
  selectedProductIds,
  monthHeaderCellHeight,
  defineHierarchyToDisplay,
  historicMonthsToDisplay,
  monthColumns,
  defineLeftCellBorder,
  sumPreviousColumnsWidth,
  evaluateLastCategoryColumn,
  headerHeight,
  unit,
  allHistoricPredictions,
  predictionData,
}) {
  const { t } = i18n;

  function calculatePrediction(month) {
    const allPredictionsFromMonth = getAllPredictionsFromMonth(month, predictionData);
    const monthProductPredictions = allPredictionsFromMonth.filter(prediction =>
      selectedProductIds.includes(prediction.productId)
    );

    const result = {};

    forecastColumns().forEach(column => {
      const values = { allPredictions: allPredictionsFromMonth, selectedPredictions: monthProductPredictions, ...column };

      result[column.name] = column.calculate(values);
    });
    return result;
  }

  return selectedProductIds.length ? (
    <tr className="font-weight-bold-on-paragraph">
      <CheckCell
        freezeColumns={freezeColumns}
        checkedProducts={checkedProducts}
        setCheckedProducts={setCheckedProducts}
        productIds={selectedProductIds}
        monthHeaderCellHeight={monthHeaderCellHeight}
        defineHierarchyToDisplay={defineHierarchyToDisplay}
        headerHeight={headerHeight}
      />
      {defineHierarchyToDisplay().length !== 0 ? (
        <td
          colSpan={defineHierarchyToDisplay().length}
          className={`background-color-white padding-sides-040rem z-2 cell-bottom-border position-sticky
          ${evaluateLastCategoryColumn(defineHierarchyToDisplay().length - 1)} ${freezeColumns ? "" : "cell-right-border"}
          `}
          style={{
            left: freezeColumns ? `${sumPreviousColumnsWidth(0)}px` : "",
            top: `${headerHeight}px`,
          }}
        >
          <Text className="font-size-17px">{t("total")}</Text>
        </td>
      ) : (
        <></>
      )}
      {historicMonthsToDisplay.map((month, i) => (
        <HistoricBodyCells
          month={month}
          key={i}
          cellLeftBorder={i !== 0}
          productIds={selectedProductIds}
          headerHeight={headerHeight}
          unit={unit}
          allHistoricPredictions={allHistoricPredictions}
          predictionData={predictionData}
        />
      ))}
      {monthColumns.map((month, index) => (
        <BodyCells
          prediction={calculatePrediction(month)}
          columns={forecastColumns()}
          productIds={selectedProductIds}
          defineLeftCellBorder={defineLeftCellBorder}
          monthIndex={index}
          key={index}
          headerHeight={headerHeight}
          unit={unit}
        />
      ))}
    </tr>
  ) : (
    <></>
  );
}
