import React from "react";
import { Text, Icon } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../utils/utils";

export default function PercentBodyCell({ prediction, showFlag, hidePercent, unit, predictionForFlagColor }) {
  function defineFlagColor() {
    const delta = predictionForFlagColor ? predictionForFlagColor : prediction;
    if (delta < 5 && delta > -5) return "color-green";
    if (delta >= 25 || delta <= -25) return "color-red";
    else return "color-dark-yellow";
  }

  return (
    <div className="row">
      {prediction !== null && !isNaN(prediction) ? (
        <>
          {showFlag && (
            <div className="col-4 d-flex justify-content-end pe-0">
              <Icon icon="flag" className={`me-1 ${defineFlagColor()}`} />
            </div>
          )}
          <div className={showFlag ? "d-flex col-8 justify-content-start ps-0" : "text-center"}>
            <Text>{`${prediction < 0 ? "- " : ""}${unit === "currency" ? "R$" : ""} ${toNDecimalsString(
              Math.abs(prediction),
              1
            )}${hidePercent ? "" : "%"}`}</Text>
          </div>
        </>
      ) : (
        <div className="col d-flex justify-content-center">
          <Text>N/A</Text>
        </div>
      )}
    </div>
  );
}
