import React, { useState } from "react";
import { Check } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

import ModalTemplate from "../ModalTemplate/ModalTemplate";

export default function ModalCheck({ renderModalContent, width }) {
  const { t } = i18n;

  const [openModal, setOpenModal] = useState(false);

  function onCloseModal() {
    setOpenModal(false);
  }

  return (
    <>
      <Check two handleChange={() => setOpenModal(true)} />
      {openModal && (
        <ModalTemplate
          width={width ? width : "500px"}
          heading={t("opportunityAction")}
          closingFunction={() => setOpenModal(false)}
        >
          {renderModalContent({ closeModal: onCloseModal })}
        </ModalTemplate>
      )}
    </>
  );
}
