import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { Menu, MenuItem, Dropdown, Text } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

import * as S from "./HeaderMenu.style";

import CustomText from "../StyledComponents/CustomText/CustomText.style";

export default function HeaderMenu({ item }) {
  const { t } = i18n;
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/").filter(segment => segment !== "");
  const mainPathSegment = pathSegments[0];

  function isActiveMenu() {
    const itemRouteWithoutSlash = item.route.substring(1);
    return mainPathSegment === itemRouteWithoutSlash ? true : false;
  }

  return (
    <S.Container active={isActiveMenu()}>
      <Menu>
        <S.Button
          onClick={() => {
            if (item.pages) setOpen(!open);
            else history.push(item.route);
          }}
        >
          <Text weight="bold">{item.menuName}</Text>
        </S.Button>
        {item.pages ? (
          <Dropdown position="bottom" open={open} setOpen={setOpen}>
            {item.pages.map(page => {
              if (page.route[0] === "/") {
                return (
                  <Link key={page.pageName} to={item.route + page.route}>
                    <MenuItem>{page.pageName}</MenuItem>
                  </Link>
                );
              } else
                return (
                  <S.MenuItemWrapper key={page.pageName}>
                    <MenuItem>
                      <S.InnerMenuItem>
                        <Text className="me-2">{page.pageName}</Text>
                        <S.TagWrapper>
                          <CustomText color="orange" weight="bold" textAlign="end" size="xs">
                            {page.route === t("waitingForData") ? t("waitingForData") : t("comingSoon")}
                          </CustomText>
                        </S.TagWrapper>
                      </S.InnerMenuItem>
                    </MenuItem>
                  </S.MenuItemWrapper>
                );
            })}
          </Dropdown>
        ) : (
          <></>
        )}
      </Menu>
    </S.Container>
  );
}
