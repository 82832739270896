import React from "react";
import { Button, Text } from "@aidron/aidron-ds";

import { capitalizeFirst, defineMonthColumns, getNextLevel } from "../../../../../utils/utils";
import { i18n } from "../../../../../translate/i18n";

import * as S from "./SecondaryCharts.style";

import ContentLoader from "../../../../../components/ContentLoader/ContentLoader";
import MultiSlideCarousel from "./MultiSlideCarousel/MultiSlideCarousel";
import MultipleProductsChart from "./MultipleProductsChart/MultipleProductsChart";

export default function SecondaryCharts({ selected, predictions, products, defineProductFilter }) {
  const { t } = i18n;

  const monthColumns = defineMonthColumns(predictions);

  const selectedLevelNumber = selected.level[selected.level.length - 1];
  const nextLevel = `level${Number(selectedLevelNumber) + 1}`;

  const nextLevelGroups = getNextLevel(selected, nextLevel, products);

  function getProductPredictions(result) {
    return predictions.filter(prediction => result.productIds.includes(prediction.productId));
  }

  return (
    <ContentLoader
      resultCondition={selected}
      contentCondition={nextLevelGroups.length}
      renderError={() => <Text>{t("errorContactAdmin")}</Text>}
    >
      <MultiSlideCarousel slideCount={nextLevelGroups.length} key={nextLevelGroups}>
        {nextLevelGroups.map((res, index) => (
          <S.ChartContainer key={index}>
            <MultipleProductsChart
              predictions={getProductPredictions(res)}
              monthColumns={monthColumns}
              heading={`${t("total")} ${capitalizeFirst(res.name)}`}
            />
            <S.ButtonContainer>
              <Button
                label={t("analyzeProducts")}
                onClick={() => defineProductFilter({ [nextLevel]: [res.name] })}
                type="outlined"
              />
            </S.ButtonContainer>
          </S.ChartContainer>
        ))}
      </MultiSlideCarousel>
    </ContentLoader>
  );
}
