import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Text } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";
import { i18n } from "../../../../translate/i18n";

import * as S from "./CardContent.style";

import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";

export default function CardContent({ label, number, legendNumber, numberColor, linkTo }) {
  const { t } = i18n;

  const history = useHistory();

  const handleClick = () => {
    history.push(linkTo);
  };

  return (
    <S.Wrapper>
      <Text allcaps weight="bold">
        {label}
      </Text>
      <CustomHeading size="sm" color={numberColor}>
        R$ {toNDecimalsString(number, 0)}
      </CustomHeading>
      <S.Legend onClick={linkTo ? handleClick : () => {}} pointer={linkTo}>
        <CustomText color={numberColor} weight="bold">
          {legendNumber}
        </CustomText>
        <Text size="xs">{legendNumber === 1 ? t("opportunity").toLowerCase() : t("opportunities").toLowerCase()}</Text>
      </S.Legend>
    </S.Wrapper>
  );
}
