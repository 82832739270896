import React, { useContext } from "react";
import { Card } from "@aidron/aidron-ds";

import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { getMonthAbbreviation } from "../../../../utils/utils";
import { i18n } from "../../../../translate/i18n";

import AreaRangeChart from "../../../../components/AreaRangeChart/AreaRangeChart";
import CardHeading from "../CardHeading/CardHeading";

export default function ConsolidatedChart({ predictions, monthColumns, heading }) {
  const { t } = i18n;
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;

  const xLabels = monthColumns.map(
    monthColumn => `${getMonthAbbreviation(monthColumn.month)}/${monthColumn.year.toString().slice(-2)}`
  );

  function defineAllRanges() {
    const values = monthColumns.map(month => {
      const monthPrediction = predictions.find(prediction => prediction.month === month.month && prediction.year === month.year);
      return [Number(monthPrediction.confidenceIntervalMin), Number(monthPrediction.confidenceIntervalMax)];
    });
    return values;
  }

  function defineFutureMonthsData() {
    const columnLabels = [
      { label: t("aiForecast"), name: "aidronPrediction" },
      { label: t("currentPlan"), name: "clientPrediction" },
    ];
    const columnColors = ["#f58b4d", "#BFBFBF"];

    return columnLabels.map((columnLabel, i) => {
      const values = monthColumns.map(month => {
        let columnLabelName = columnLabel.name;
        const monthPrediction = predictions.find(
          prediction => prediction.month === month.month && prediction.year === month.year
        );
        const value = monthPrediction?.[columnLabelName];
        return value ? value : null;
      });
      return {
        label: columnLabel.label,
        color: columnColors[i],
        values: values,
        marker: true,
        lineStyle: "shortDash",
      };
    });
  }

  return (
    <Card>
      <CardHeading heading={heading} />
      <AreaRangeChart
        xLabels={xLabels}
        ranges={defineAllRanges()}
        lines={defineFutureMonthsData()}
        colors={["#f58b4d", "#BFBFBF", "#00BECB"]}
        unit={unit}
      />
    </Card>
  );
}
