const messages = {
  en: {
    translations: {
      headings: {
        dashboard: "Dashboard",
        forecast: {
          title: "Forecast",
          downloads: "Downloads",
          analysis: "Analysis",
        },
        automations: "Automations",
        settings: "Settings",
      },
      sideBar: {
        editAvatarHeading: "Edit your photo",
        addAvatar: "Add photo",
        deleteAvatar: "Delete photo",
      },
      languageSelector: "Language",
      company: "Company",
      login: {
        title: "Sign in to our platform",
        emailLabel: "Your email",
        emailPlaceholder: "example@company.com",
        passwordLabel: "Your password",
        passwordPlaceholder: "mypassword",
        signIn: "Sign In",
        error: "Invalid user and/or password!",
        logOut: "Log Out",
      },
      settings: "Settings",
      myAccount: "My account",
      users: "Users",
      organization: "Organization",
      logout: "Log out",
      profilePicture: "Profile picture",
      chosePicture: "Chose picture",
      permissions: "Permissions",
      user: "User",
      role: "Role",
      productHierarchy: "Product hierarchy",
      numberOfLevels: "Number of levels",
      level: "Level",
      levelName: "Level name",
      channelRegistration: "Channel registration",
      // FORECAST SECTION:
      year: "Year",
      month: "Month",
      weeks: "Weeks",
      months: "Months",
      filterBy: "Filter by",
      generatedOn: "Forecast generated on",
      generationDate: "Generation date",
      forecastDate: "Forecast date",
      commercialWeek: "Commercial week",
      commercialWeeks: "Commercial weeks",
      monthlyForecast: "Forecast months",
      showFilter: "Filters",
      hideFilter: "Hide filters",
      removeFilters: "Remove filters",
      products: "Products",
      fileError: "The selected file is unavailable. Select different dates",
      summary: "Download summary",
      singleFile: "Single file (.xlsx)",
      singleFileCaption: "A sheet per week",
      multipleFiles: "Multiple files (.zip)",
      multipleFilesCaption: "A file per week",
      exportMultiple: "Export multiple",
      advancedExport: "Advanced export",
      forecasts: "Forecasts",
      selectCommWeek: "Forecasts generated in the following commercial weeks:",
      next: "Next",
      changeWeekModal: {
        body: "If you change the selected commercial weeks, the applied product filters will be reset.",
      },
      attention: "Warning!",
      options: "Options",
      downloadSummary: "Download summary",
      addAnotherMale: "Add another",
      addAnotherFemale: "Add another",
      addPeriod: "Add period",
      commercialCalendar: "Commercial Calendar",
      insertYear: "Insert a year between 1000 and 9999",
      sort: "Sort",
      graphicHeading: "Graphic Analysis",
      descriptiveHeading: "Descriptive Analysis",
      classicHeading: "Classic Analysis",
      productFilter: "Filter by product",
      brand: "Collection",
      griffe: "Griffe",
      grife: "Grife",
      line: "Line",
      group: "Group",
      subgroup: "Subgroup",
      colecao: "Collection",
      linha: "Line",
      marca: "Brand",
      grupo: "Group",
      subgrupo: "Subgrupo",
      subgrupos: "Subgroups",
      graphType: "Graph style",
      lines: "Lines",
      columns: "Columns",
      showing: "Showing",
      product: "Product",
      estimated: "Estimated",
      realized: "Realized",
      percentDiff: "Diff %",
      diff: "Difference %",
      wape: "WAPE",
      selectOneOption: "Select one option",
      selectAtLeastOne: "Select at least one option",
      sortBy: "Sort by",
      highest: "Highest",
      lowest: "Lowest",
      // Página "Análise Descritiva"
      aidronView: "Aidron view",
      classicView: "Classic view",
      resultsSubtitle: "Demand forecast (in clothing pieces)",
      week: "Week",
      analyseGraph: "Analyse graph",
      all: "All",
      choseProducts: "Chose products",
      checkAll: "Select all",
      uncheckAll: "Deselect all",
      analyse: "Analyse",
      close: "Close",
      estActDif: "Est  |  Act  |  Diff%",
      centerMonth: "Center month",
      centerWeek: "Center week",
      expand: "Expand data",
      shrink: "Shrink data",
      selectedProducts: "Selected products",
      // Página "Visualização Clássica"
      hideColumns: "Hide columns",
      unhideColumns: "Unhide columns",
      filterByDate: "Filter by date",
      filterByName: "Filter by name",
      searchLabel: "Search product...",
      searchError: "No results found for your search.",
      exportData: "Export data",
      aidronForecast: "AidronForecast",
      changeCommWeek: "Change commercial week",
      editEstimated: "Edit estimations",
      finishRevision: "Finish revision",
      revision: "Revision",
      revised: "Revised",
      send: "Send",
      reviewAndSend: "Review and send",
      typeYear: "Type a year..",
      forecastPeriod: "Forecast period",
      showHistory: "Show history",
      analyseAndIntegratePredictions: "Analyse and integrate forecasts",
      analysePredictions: "Analyse predictions",
      saveRevision: "Save revision",
      closeRevision: "Close revision",
      cancel: "Cancel",
      dontSave: "Discard changes",
      forecastRevision: "Forecast revision",
      unsavedChangesModal: {
        heading: "Save changes?",
        body: "There are unsaved values in the forecast revision. Do you wish to save these changes?",
        promptBody: "There are unsaved values in the forecast revision. Are you sure you want to leave?",
      },
      dataSent: "Data was successfully sent!",
      sent: "Sent",
      submittedOn: "Submitted on",
      sendingPreviewModal: {
        heading: "Submit summary",
        alreadySentProducst: "You selected items that were already sent, so these were withdrawn from the list above.",
        body: "Review the data that will be sent. To edit, go back to the previous page.",
      },
      noSendingIntegrationModal: {
        heading: "There is no integration with your planning system!",
        body: "You have no integration with your planning system. We generated an export file with this version of the forecast.",
        downloadFile: "Download file",
      },
      back: "Back",
      formerWeekModal: {
        heading: "Confirm the commercial week",
        bodyEditing: "The forecast you are analysing was generated in the commercial week number ",
        bodySending: "The forecast you are about to send to your system was generated in the commercial week number ,",
        body2: " of ",
        body3: ", which is not the latest forecast issued.",
        question: "How do you wish to continue?",
        stay: "Stay in week ",
        goToCurrent: "Go to latest commercial week",
      },
      savedUnsent: "Saved and unsent",
      seeSendingStatus: "See sending status",
      sendingStatus: "Sending status",
      productsAmount: "No of products",
      seeDetails: "See details",
      hideDetails: "Hide details",
      noCheckedProductsModal: {
        heading: "There are no selected products!",
        body: "In order to continue, select one or more products from the table.",
      },
      productRegistration: "Product registration",
      remove: "Remove",
      hideProducts: "Hide products",
      showRegisteredProducts: "Show registered products",
      saveModifications: "Save changes",
      errorModal: {
        heading: "Oops! Something went wrong",
        checkFields: "Check the highlighted fields and try again.",
        changesNotSaved: "Changes were not saved. Please contact the system administrator.",
      },
      typeValidName: "Type a valid name",
      season: "Season",
      seasonRegistration: "Seasons registration",
      hideSeasons: "Hide seasons",
      showRegisteredSeasons: "Show registered seasons",
      and: "and",
      filterBySeason: "Filter by season",
      predictions: "Predictions",
      showSeasons: "Show seasons",
      bulkImport: "Bulk import",
      seasonsBulkImport: "Seasons bulk import",
      step: "Step",
      importModal: {
        step1Instruction: "Download the template and fill it according to the example inside it.",
        step2Instruction: "Upload the template containing the data to be registered.",
        step3Instruction: "Verify the data to be imported and confirm the import.",
        error:
          "There was an error on importing data. Verify the inputted data in the file and, if the problem persists, please contact the system administrator.",
      },
      choseFile: "Chose file",
      fileUploaded: "The file was uploaded!",
      confirm: "Confirm",
      seasonNumber: "Season number",
      seasonNumbers: "Season numbers",
      seasonName: "Season name",
      seasonNames: "Season names",
      seasonMonths: "Season months",
      importSeasons: "Import seasons",
      importProducts: "Import products",
      percentDifference: "Percent difference",
      productNumber: "Product number",
      example: "Example",
      optional: "Optional",
      consultOnSeasonsSheet: 'Consult number in the "Season Numbers" sheet',
      new: "New",
      seasons: "Seasons",
      wereSaved: "wereSaved",
      wereNotSaved: "were NOT saved",
      verifyData: "Check the inputted data and try again. If the problem persists, contact the system administrator.",
      simulateActions: "Simulate actions",
      simulateActionsSubtitle: "Simulate various future scenarios in real-time",
      exploreScenarios: "Explore internal and external scenarios",
      actionsCheckout: "Actions Checkout",
      actionsCheckoutSubtitle: "Actions marked for adjustment",
      salesOpportunities: "Sales Opportunities",
      buyOptimization: "Purchase Optimization",
      storesOpportunities: "Store Opportunities",
      comingSoon: "Coming soon",
      opportunity: "Opportunity",
      opportunities: "Opportunities",
      inCheckout: "in checkout",
      stockOrSalesIncreaseOptimization: "Stock or sales increase optimization",
      totalOpportunities: "Total opportunities",
      costReduction: "cost reduction",
      channels: "Channels",
      adjustingPlanAndStock: "adjusting plan and stock",
      assortmentAdjustment: "Assortment adjustment",
      additionalSale: "Additional Sale",
      addingStockAndSales: "adding stock and sales",
      above: "above",
      below: "Below",
      productPlan: "Product plan",
      piecesOfClothingAbbreviation: "items",
      theForecast: "forecast",
      paretoAnalysis: "Pareto Analysis",
      ofTheTotal: "of the total",
      eightyPercentOfGap: "products account for 80% of the gap",
      channelsTarget: "Channels target",
      waitingForData: "Waiting for data",
      currentPlanVsForecast: "Current plan vs. Forecast",
      monthsSumDifference: "Difference compared to the sum of all months",
      productOpportunities: "Product Opportunities",
      channelOpportunities: "Channel Opportunities",
      marketInsights: "Market Insights",
      analysis: "Analysis",
      productPlanVsForecast: "Product: Plan vs. Forecast",
      channelsTargetVsForecast: "Channels: Target vs. Forecast",
      forecastAccuracy: "Forecast Accuracy",
      actions: "Actions",
      adjustProductPlan: "Adjust Product Plan",
      avoidSalesLoss: "Avoid Sales Loss",
      optimizePurchase: "Optimize Purchase",
      highestValue: "highest value",
      lowestValue: "lowest value",
      showAllOpportunities: "Show All Opportunities",
      opportunityType: "Opportunity Type",
      orderBy: "Order by",
      done: "Done",
      additionalSaleExplanation:
        "This opportunity is mapped whenever your future sales plan is below the center of customer demand (customer demand for this product can be observed in green on the chart).",
      expectedIncreaseInSales: "Expected Increase in Sales",
      avoidSalesLossExplanation:
        "This opportunity is identified whenever sales from recent months are below or close to the minimum threshold of customer demand.",
      optimizePurchaseExplanation:
        "This opportunity is mapped whenever your future sales plan is above the maximum threshold of demand for this product. On the chart, this demand threshold can be observed in green.",
      futurePurchaseReduction: "Future Purchase Reduction",
      active: "Active",
      reactivated: "Reactivated",
      checkout: "Checkout",
      percent: "Percentage",
      opportunitySize: "Opportunity Size",
      status: "Status",
      deletion: "Deletion",
      reopen: "Reopen",
      wishReopenOpportunity: "Do you wish to reopen the opportunity?",
      opportunityWillBeAnalysed: "The opportunity will be analyzed",
      opportunityWillCheckout: 'By selecting this option, the opportunity will receive the "Checkout" status.',
      opportunityHasGoneCheckout: "The opportunity has been sent to Action Checkout!",
      notAnOpportunity: "Not an opportunity",
      describeDeletionReason:
        "By selecting this option, you can describe the reason for deletion, and the opportunity will be removed from the opportunity list.",
      opportunityHasBeenRemoved: "The opportunity has been removed.",
      removeFromCheckout: "Remove from Checkout",
      opportunityWillRemainInList:
        'By selecting this option, the opportunity will remain in the opportunity list with the "Active" status.',
      opportunityHasLeftCheckout: "The opportunity has been removed from the Checkout list.",
      errorContactAdmin: "An error occurred. Please try again or contact the system administrator.",
      finish: "Finish",
      describeReason: "Briefly describe the reason",
      remainingCharacters: "Remaining Characters",
      transforInAction: "Transform into Action",
      removeOpportunity: "Discard Opportunity",
      save: "Save",
      productHasOtherOpportunity: "This product has another opportunity",
      productHasOtherOpportunities: "This product has other opportunities",
      analyseOpportunity: "Analyze Opportunity",
      analyseOpportunities: "Analyze Opportunities",
      actionRecommendation: "Action Recommendation",
      advancedAnalysis: "Advanced Analysis",
      currentPlan: "Current Plan",
      opportunityChartError:
        "An error occurred while loading the chart for this opportunity. Please contact the system administrator.",
      willBeListedAsOpportunity: "It will be listed again as an Opportunity, and its value will be included in the active total.",
      removedOpportunities: "Removed Opportunities",
      opportunityWasReopened: "The opportunity has been reopened.",
      noRemovedOpportunities: "There are no removed opportunities.",
      period: "Period",
      opportunityTypeTitle: "{{opportunityTitle}} Opportunity",
      help: "Help",
      planVsForecastSubtitle:
        "Analysis of the main discrepancies between the company's official plan and the Forecast generated by Aidron",
      planVsForecastAnalysis: "Current Plan vs Forecast Analysis",
      mainGrouping: "Main Grouping",
      salesImpact: "Sales Impact",
      factorsThatImpactSales: "Factors That Impact Sales",
      groupingTitle: "{{groupingName}} grouping",
      planVsForecast: "Plan vs Forecast",
      monthlyBreakdown: "Monthly Breakdown",
      aiForecast: "AI Forecast",
      greatPerformance: "Great Performance",
      ofAboveThanDemandProducts: "of products with sales above demand",
      criticalPoints: "Criticals",
      ofBelowThanDemandProducts: "of products with sales below demand",
      productDriversListingAndPercent: "Below is the list of factors impacting sales and the percentage impact of each",
      upcomingFeature: "This feature will be available soon.",
      mainGroupingBreakdown: "Main Grouping Breakdown",
      total: "Total",
      analyzeProducts: "Analyze Products",
      accuracyAnalysis: "Accuracy Analysis",
      activeOpportunities: "Active Opportunities",
      possibleUpcomingFeature: "This feature may soon be available for your company.",
      productDrivers: "Factors Impacting This Product's Sales",
      productDriversExplanation:
        "This analysis identifies the % impact of each factor on this product's sales. It is essential for diagnostics and proposing actions to optimize stock and/or increase sales.",
      realizedDeviationVsDemand: "Executed Deviation vs Demand",
      realizedDeviationVsDemandDescription:
        "Average percentage of deviations between actual results and customer demand over the last two months. The higher this indicator, the less predictable the item, potentially indicating operational issues that need resolution to make the plan more accurate.",
      lastMonthSaleVariation: "Last Month's Sales Variation",
      lastMonthSaleVariationDescription:
        "Percentage variation between sales in the second-to-last and last months. When a variation spike occurs (and it's not due to a special date between the months), this may indicate an unforeseen sudden change.",
      saleAnomalyProbability: "Sale Anomaly Probability",
      saleAnomalyProbabilityDescription:
        "This value indicates the probability that the AI identified an operational issue in historical sales. A high index suggests the last month's sales variation is not just due to customer demand but might reflect operational actions (e.g., price changes, assortment strategy, etc.).",
      aiAccuracyOverClient: "AI Accuracy vs Plan",
      aiAccuracyOverClientDescription:
        "A positive number means the AI outperformed the Plan over the last six months. Zero indicates no difference for the total period. A negative value means the AI underperformed the Plan. However, if accuracy is low and the anomaly probability is high, operational factors outside the AI's variables may need to be addressed to improve the models.",
      indexesToSupport: "Indexes to Support the Analysis",
      productView: "Product View",
      forecastGeneratedIn: "Forecast generated on",
      indicator: "Indicator",
      searchByHierarchy: "Search by hierarchy",
      type: "Type",
      filterByHierarchy: "Filter by hierarchy",
      clearFilters: "Clear filters",
      analyzeProduct: "Analyze products",
      salesHistory: "Sales history",
      freezeColumns: "Freeze columns",
      choseColumns: "Chose columns",
      "Precipitação (Quantidade de chuvas)": "Precipitation (Rainfall)",
      "Índice Geral de Preços (IGPM)": "General Price Index (IGPM)",
      "Índice de confecção de artigos do vestuário e acessórios": "Garment and Accessories Manufacturing Index",
      "Índice de importação de itens de vestuário": "Clothing Items Import Index",
      Preço: "Price",
      Margem: "Margin",
      "Índice de vendas reais no varejo": "Real Retail Sales Index",
      "Índice de vendas reais no varejo do vestuário": "Clothing Retail Real Sales Index",
      Temperatura: "Temperature",
      "Brasileiros comprando itens de vestuário fora do Brasil": "Brazilians Buying Clothing Items Abroad",
      Chuva: "Rain",
      "Produção de artigos do vestuário e acessórios no Brasil": "Garment and Accessories Production in Brazil",
      "Venda do Varejo de vestuário Brasil": "Clothing Retail Sales in Brazil",
      "Venda do Varejo no Brasil": "Retail Sales in Brazil",
      "Fluxo de Clientes (Índices do Varejo no Brasil)": "Customer Flow (Retail Indices in Brazil)",
      percentParticipation: "Participation %",
      ai: "AI",
      differenceAidronAndClient: "Diff AI x Current Plan",
      percentAidronVsClient: "Diff% AI x Current Plan",
      partPercent: "Part %",
      maxPot: "Max Pot",
      minPot: "Min Pot",
      maxPotential: "Maximum Potential",
      minPotential: "Minimum Potential",
      projection: "Projection",
      realizedSales: "Realized Sales",
      upperLimitAbbreviation: "Up. Limit",
      upperLimit: "Upper Limit",
      lowerLimitAbbreviation: "Low. Limit",
      lowerLimit: "Lower Limit",
      percentDiffAIVsProjection: "Diff% AI x Projection",
      percentDiffCurrentPlanVsProjection: "Diff% Current Plan x Projection",
      percentParticipationOnRealized: "% Participation in Realized",
      percentDifferenceAIVsRealized: "Diff% AI x Realized",
      percentDifferenceCurrentPlanVsRealized: "Diff% Current Plan x Realized",
      percentParticipationInRealized: "% Participation in Realized",
      percentDifferenceAIVsStorePlan: "Diff% AI x Store Plan",
      aiAdjustment: "AI Adjustment",
      newPlan: "New Plan",
      lastForecastGeneratedOn: "Last forecast generated on",
      analyzeProductAccuracy: "Analyze Product Accuracy",
      customerDemand: "Customer Demand",
      stockOptimization: "Stock Optimization",
      opportunitiesCheckout: "Opportunities Checkout",
      checkoutSummary: "Checkout Summary",
      noOpportunitiesGoTo: "No opportunities were sent to Checkout. To do so, go to the",
      opportunitiesPage: "Opportunities page",
      opportunityWasFinalized: "Opportunity was finalized",
      storeSalesIncrease: "Store Sales Increase",
      opportunityWillBeFinalized:
        "By clicking Ok, this opportunity will be finalized and marked as Applied in the company's current Planning",
      newPhotoSaved: "Your new photo has been successfully saved.",
      newPasswordSaved: "New password successfully saved!",
      newPasswordNotSaved:
        "Your password could NOT be saved. Please try again in a moment. If the problem persists, contact the system administrator.",
      changePassword: "Change password",
      newPassword: "New password",
      repeatNewPassword: "Repeat new password",
      savePassword: "Save password",
      newPlanUnsavedChanges: "There may be unsaved changes in the New Plan edit. Are you sure you want to leave?",
      adjustSalesPlan: "Adjust Sales Plan",
      backToOriginalValue: "Return to Original Value",
      clientDemandVsPlan: "Client Demand vs. Plan",
      salesProblemProbability: "Sales Problem Probability",
      differenceAIAccuracyVsPlan: "Difference in AI Accuracy vs. Plan",
      featureComingSoon: "This feature will be available soon.",
      differenceFromPlan: "Difference from Plan",
      avoidedStockSurplus: "Avoided Stock Surplus",
      increaseInSales: "Increase in Sales",
      newPlanAnalysis: "New Plan Analysis",
      saving: "Saving",
      aiAdjustmentsFilter: "Filter AI Adjustments",
      removeFilter: "Remove Filter",
      filter: "Filter",
      current: "Current",
      difference: "Difference",
      cancelEdit: "Cancel edit",
      edit: "Edit",
      integrate: "Integrate",
      userActivity: "User Activity",
      opportunityAddedToCheckout: "Opportunity added to Actions Checkout!",
      opportunitySuccessfullyRemoved: "Opportunity successfully removed!",
      flowDependencyForSelling: "Flow Dependency for Selling",
      flowDependencyForSellingExplanation:
        "If the % is very high, the factors listed above will have little effect unless the customer flow increases in locations where this product has high sales. For products with Flow Dependency above 60%, we recommend operational actions to bring more visibility to the product.",
      opportunityAction: "Opportunity Action",
      consolidatedView: "Consolidated View",
      finalizeAction: "Finalize Action",
      finalizeOpportunity: "Finalize Opportunity?",
    },
  },
};

export { messages };
