import React from "react";
import { i18n } from "../../../../../translate/i18n";

import ButtonWithModal from "../../../../../components/ButtonWithModal/ButtonWithModal";
import ProductDriversChart from "./ProductDriversChart/ProductDriversChart";
import ProductDriversData from "../../../../../components/ProductDriversData/ProductDriversData";

export default function ProductDriversButton({ productId }) {
  const { t } = i18n;
  return (
    <ButtonWithModal
      label={t("salesImpact")}
      icon="arrow-up-right"
      type="outlined"
      modalHeading=" "
      modalWidth="700px"
      renderModalContent={() => (
        <ProductDriversData productId={productId}>
          <ProductDriversChart />
        </ProductDriversData>
      )}
    />
  );
}
