import React, { useContext } from "react";
import { Text } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { ForecastContext } from "../../../../templates/context/ForecastContext";

import { getForecastPredictions, getAllPredictionsFromMonth } from "../../../../utils/utils";
import { accuracyColumnsCurrentMonth, accuracyColumnsPast } from "../../../../utils/tableColumns";
import { i18n } from "../../../../translate/i18n";

import BodyCells from "../../../../components/BodyCells/BodyCells";
import CheckCell from "../../../../components/CheckCell/CheckCell";

export default function AccuracySubtotalRow({
  freezeColumns,
  checkedProducts,
  setCheckedProducts,
  selectedProductIds,
  defineHierarchyToDisplay,
  monthColumns,
  sumPreviousColumnsWidth,
  evaluateLastCategoryColumn,
  headerHeight,
  currentMonth,
  unit,
  allHistoricPredictions,
  defineLeftCellBorder,
  predictionData,
}) {
  const { t } = i18n;

  const { userInfo } = useContext(CompanyContext);
  const { historicForecasts } = useContext(ForecastContext);

  const companyName = userInfo.company.name;

  const predictionDataProducts = predictionData.map(pred => pred.productId);
  const noFilterProductIds = [...new Set(predictionDataProducts)];

  function getOfficialPastForecast(month) {
    return historicForecasts.find(forecast => forecast.month === month.month && forecast.year === month.year);
  }

  function getPredictionsFromOfficialPastForecast(month) {
    const officialPastForecast = getOfficialPastForecast(month);

    if (officialPastForecast) {
      return getForecastPredictions(officialPastForecast.forecastId, allHistoricPredictions);
    } else {
      // Cai aqui quando não há predição oficial anterior, pegar alguma que tenha valor de realizado para aquele mês
      return getForecastPredictions(10, allHistoricPredictions);
    }
  }

  function calculatePrediction(month, predictions) {
    const isCurrentMonth = month.month === currentMonth.month && month.year === currentMonth.year;

    const columns = isCurrentMonth ? accuracyColumnsCurrentMonth({ companyName }) : accuracyColumnsPast();

    const monthPredictions = getAllPredictionsFromMonth(month, predictions);

    const allPredictions = monthPredictions.filter(prediction => noFilterProductIds.includes(prediction.productId));
    const selectedPredictions = monthPredictions.filter(prediction => selectedProductIds.includes(prediction.productId));

    const result = {};

    columns.forEach(column => {
      const values = { allPredictions: allPredictions, selectedPredictions: selectedPredictions, ...column };

      result[column.name] = column.calculate(values);
    });
    return result;
  }

  return selectedProductIds.length > 0 ? (
    <tr className="font-weight-bold-on-paragraph">
      <CheckCell
        freezeColumns={freezeColumns}
        checkedProducts={checkedProducts}
        setCheckedProducts={setCheckedProducts}
        productIds={selectedProductIds}
        defineHierarchyToDisplay={defineHierarchyToDisplay}
        headerHeight={headerHeight}
      />
      {defineHierarchyToDisplay().length !== 0 ? (
        <td
          colSpan={defineHierarchyToDisplay().length}
          className={`background-color-white padding-sides-040rem z-2 cell-bottom-border position-sticky
          ${evaluateLastCategoryColumn(defineHierarchyToDisplay().length - 1)} ${freezeColumns ? "" : "cell-right-border"}
          `}
          style={{
            left: freezeColumns ? `${sumPreviousColumnsWidth(0)}px` : "",
            top: `${headerHeight}px`,
          }}
        >
          <Text className="font-size-17px">{t("total")}</Text>
        </td>
      ) : (
        <></>
      )}
      {monthColumns.map((month, index) => (
        <BodyCells
          columns={accuracyColumnsPast()}
          prediction={calculatePrediction(month, getPredictionsFromOfficialPastForecast(month))}
          productIds={selectedProductIds}
          monthIndex={index}
          headerHeight={headerHeight}
          key={index}
          cellTopBorder
          unit={unit}
          month={month}
          defineLeftCellBorder={defineLeftCellBorder}
        />
      ))}
      <BodyCells
        columns={accuracyColumnsCurrentMonth({ companyName })}
        prediction={calculatePrediction(currentMonth, predictionData)}
        productIds={selectedProductIds}
        headerHeight={headerHeight}
        cellTopBorder
        unit={unit}
        month={currentMonth}
        defineLeftCellBorder={defineLeftCellBorder}
      />
    </tr>
  ) : (
    <></>
  );
}
