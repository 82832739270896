import styled from "styled-components";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 3rem !important;

  @media (min-width: 992px) {
    height: 100vh !important;
    margin-top: 0 !important;
  }
`;

const UpperSection = styled.div`
  display: flex;
  justify-content: end;
  padding: 1rem 5rem 0 0;
`;

export { Container, UpperSection };
