import React from "react";
import { toNDecimalsString } from "../../../../../../../utils/utils";
import { i18n } from "../../../../../../../translate/i18n";

import * as S from "./ChartSeries.style";

import CustomHeading from "../../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function ChartSeries({ series }) {
  const { t } = i18n;
  const { driver, value } = series;

  return (
    <S.Grid>
      <S.TextContainer>
        <CustomText textAlign="end" size="xs" margin="0" lineHeight="120%">
          {t(driver)}
        </CustomText>
      </S.TextContainer>
      <S.OuterBar>
        <S.InnerBar width={value} />
      </S.OuterBar>
      <CustomHeading color="orange" size="md">
        {toNDecimalsString(value, 0)}%
      </CustomHeading>
    </S.Grid>
  );
}
