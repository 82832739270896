import React from "react";
import { Text } from "@aidron/aidron-ds";
import { i18n } from "../../../../../../translate/i18n";

import * as S from "./ProductDriversChart.style";

import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import ChartSeries from "./ChartSeries/ChartSeries";
import ContentLoader from "../../../../../../components/ContentLoader/ContentLoader";
import CustomHeading from "../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import IconButtonWithModal from "../../../../../../components/IconButtonWithModal/IconButtonWithModal";
import ClientFlowSection from "../../../../../../components/ClientFlowSection/ClientFlowSection";

export default function ProductDriversChart({ productId, productDrivers, orderedProductDrivers, clientFlow }) {
  const { t } = i18n;
  return (
    <ContentLoader
      resultCondition={productDrivers}
      contentCondition={productDrivers?.length}
      renderError={() => <Text>{t("possibleUpcomingFeature")}</Text>}
    >
      <S.HeadingContainer>
        <ProductHierarchyHeading productId={productId} />
      </S.HeadingContainer>
      <S.TitleContainer>
        <CustomHeading size="sm">{t("productDrivers")}</CustomHeading>
        <IconButtonWithModal
          icon="help-circle"
          modalHeading={`${t("help")}: ${t("productDrivers")}`}
          renderModalContent={() => <Text>{t("productDriversExplanation")}</Text>}
        />
      </S.TitleContainer>
      <S.OuterChartContainer>
        <S.InnerChartContainer>
          {orderedProductDrivers.map(driver => (
            <ChartSeries series={driver} key={driver.driver} />
          ))}
        </S.InnerChartContainer>
      </S.OuterChartContainer>

      <ClientFlowSection clientFlow={clientFlow} />
    </ContentLoader>
  );
}
