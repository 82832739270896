import React, { useContext, useState, useMemo, useEffect } from "react";

import { CompanyContext } from "../../../templates/context/CompanyContext";
import { ForecastContext } from "../../../templates/context/ForecastContext";
import { getPredictions, getProductIds } from "../../../services/PredictionsService";
import { defineMonthColumns, toLocaleDate } from "../../../utils/utils";
import { createUserEvent } from "../../../services/UserEventsService";
import { i18n } from "../../../translate/i18n";

import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import NewTableOptions from "../../../components/NewTableOptions/NewTableOptions";
import MonthColumnFilter from "../../../components/MonthColumnFilter/MonthColumnFilter";
import PredictionsTable from "../PredictionsTable/PredictionsTable";
import PlanVsForecastChartCarousel from "./PlanVsForecastChartCarousel/PlanVsForecastChartCarousel";

export default function ProductView({ selectedLevels, setSelectedLevels, inputtedExpressions, setInputtedExpressions }) {
  const { t } = i18n;

  const { hierarchy, userInfo } = useContext(CompanyContext);
  const { historicForecasts, selectedForecast } = useContext(ForecastContext);
  const { day, month, year } = selectedForecast;
  const generationDate = toLocaleDate(day, month, year);

  const initialHistoricForecasts = userInfo.company.id === 4 ? historicForecasts.slice(-4, -1) : historicForecasts.slice(-3);
  const initialHistoricForecastIds = initialHistoricForecasts.map(forecast => forecast.forecastId);

  const lastHistoricForecast = initialHistoricForecasts[initialHistoricForecasts.length - 1];
  const initialSortBy = {
    orderForecastId: lastHistoricForecast.forecastId,
    columnLabel: "realized",
    order: "descending",
    months: [lastHistoricForecast.month],
    year: lastHistoricForecast.year,
  };

  const [freezeColumns, setFreezeColumns] = useState(true);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [sortRowsBy, setSortRowsBy] = useState(initialSortBy);
  const [selectedHierarchyIndexes, setSelectedHierarchyIndexes] = useState(hierarchy.levels.map((level, index) => index));
  const [historicPredictions, setHistoricPredictions] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [selectedHistoricForecastIds, setSelectedHistoricForecastIds] = useState(initialHistoricForecastIds);

  const token = localStorage.getItem("token");

  const monthColumns = useMemo(() => defineMonthColumns(predictions), [predictions]);

  const historicMonthsToDisplay = useMemo(() => {
    return historicForecasts.filter(historicForecast => selectedHistoricForecastIds.includes(historicForecast.forecastId));
  }, [selectedHistoricForecastIds]);

  useEffect(() => {
    fetchCurrentPredictions();
  }, []);

  useEffect(() => {
    fetchHistoricPredictions();
  }, [selectedHistoricForecastIds]);

  useEffect(() => {
    fetchProductIds();
  }, [sortRowsBy]);

  async function fetchCurrentPredictions() {
    const result = await getPredictions(token, [selectedForecast.forecastId]);
    setPredictions(result.predictions);
  }

  async function fetchHistoricPredictions() {
    const result = await getPredictions(token, selectedHistoricForecastIds);
    setHistoricPredictions(result.predictions);
  }

  async function fetchProductIds() {
    const result = await getProductIds(token, selectedForecast.forecastId, sortRowsBy);
    setProductIds(result.productIds);
    setSelectedProductIds(result.productIds);
  }

  useEffect(() => {
    const isAnyProductFiltered = selectedProductIds.length < productIds.length;
    if (isAnyProductFiltered) {
      const token = localStorage.getItem("token");
      createUserEvent(token, { page: "Análise Produto", event: "Filtro Análise Produto" });
      createUserEvent(token, { page: "Análise Produto", event: "Filtro Análise Produto", productId: selectedProductIds });
    }
  }, [selectedProductIds]);

  return (
    <>
      <SectionHeader heading={t("productView")} subtitles={[`${t("forecastGeneratedIn")} ${generationDate}`]} />

      <NewTableOptions
        checkedProducts={checkedProducts}
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
        freezeColumns={freezeColumns}
        setFreezeColumns={setFreezeColumns}
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        renderHistoricFilter={({ closeModal }) => (
          <MonthColumnFilter
            historicForecasts={historicForecasts}
            onConcludedClick={closeModal}
            setSelectedHistoricForecastIds={setSelectedHistoricForecastIds}
            selectedHistoricForecastIds={selectedHistoricForecastIds}
          />
        )}
        renderGraphicAnalysis={() => (
          <PlanVsForecastChartCarousel
            checkedProducts={checkedProducts}
            monthColumns={monthColumns}
            historicMonthsToDisplay={historicMonthsToDisplay}
            allHistoricPredictions={historicPredictions}
            predictionData={predictions}
          />
        )}
        downloadButton
        hierarchyColumnsButton
        selectedLevels={selectedLevels}
        setSelectedLevels={setSelectedLevels}
        inputtedExpressions={inputtedExpressions}
        setInputtedExpressions={setInputtedExpressions}
      />

      <PredictionsTable
        freezeColumns={freezeColumns}
        monthColumns={monthColumns}
        checkedProducts={checkedProducts}
        setCheckedProducts={setCheckedProducts}
        sortRowsBy={sortRowsBy}
        setSortRowsBy={setSortRowsBy}
        historicMonthsToDisplay={historicMonthsToDisplay}
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        allHistoricPredictions={historicPredictions}
        selectedProductIds={selectedProductIds}
        predictionData={predictions}
      />
    </>
  );
}
