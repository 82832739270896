import React, { useState, useEffect, useContext } from "react";
import { Card } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";

import { ForecastContext } from "../../../templates/context/ForecastContext";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getConsolidatedPredictions, getPredictions, getProductIds } from "../../../services/PredictionsService";
import { capitalizeFirst, defineMonthColumns, defineGroupFilterOptions } from "../../../utils/utils";
import { consolidatedForecastColumns } from "../../../utils/tableColumns";

import * as S from "./ConsolidatedView.style";

import ConsolidatedViewHeader from "../../../components/ConsolidatedViewHeader/ConsolidatedViewHeader";
import ConsolidatedChart from "./ConsolidatedChart/ConsolidatedChart";
import ConsolidatedTable from "../../../components/ConsolidatedTable/ConsolidatedTable";
import IndexesCard from "./IndexesCard/IndexesCard";
import SecondaryChartsSection from "./SecondaryChartsSection/SecondaryChartsSection";
import ButtonWithModal from "../../../components/ButtonWithModal/ButtonWithModal";
import LevelDriversModal from "./LevelDriversModal/LevelDriversModal";
import HierarchyGroupFilter from "../../../components/HierarchyGroupFilter/HierarchyGroupFilter";

export default function ConsolidatedView({ defineProductFilter }) {
  const { t } = i18n;

  const { selectedForecast } = useContext(ForecastContext);
  const { allProducts } = useContext(CompanyContext);

  const [consolidatedPredictions, setConsolidatedPredictions] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedValue, setSelectedValue] = useState(1);

  const selected = defineGroupFilterOptions(products).find(option => option.value === selectedValue);

  const groupingName = capitalizeFirst(selected.displayName);

  const monthColumns = defineMonthColumns(consolidatedPredictions);

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchConsolidatedData();
    fetchPredictions();
  }, []);

  async function fetchConsolidatedData() {
    const result = await getConsolidatedPredictions(token, [selectedForecast.forecastId]);
    setConsolidatedPredictions(result[0]);
  }

  async function fetchPredictions() {
    const token = localStorage.getItem("token");
    const forecastId = selectedForecast.forecastId;
    const sortBy = {
      orderForecastId: forecastId,
      order: "any",
      months: [selectedForecast.month],
    };

    const [predictions, productIds] = await Promise.all([
      getPredictions(token, [forecastId]),
      getProductIds(token, selectedForecast.forecastId, sortBy),
    ]);

    setPredictions(predictions.predictions);

    const predictionProductIds = productIds.productIds;
    const products = allProducts.filter(product => predictionProductIds.includes(product.productId));
    setProducts(products);
  }

  return (
    <S.OuterContainer>
      <ConsolidatedViewHeader />
      <Card>
        <S.GroupingContainer>
          <HierarchyGroupFilter
            label={`${t("mainGrouping")}:`}
            selected={selected.value}
            products={products}
            onChange={setSelectedValue}
          />
          <ButtonWithModal
            label={t("salesImpact")}
            renderModalContent={() => <LevelDriversModal productIds={selected?.productIds} selected={selected} />}
            type="outlined"
            icon="arrow-up-right"
            modalHeading={`${t("factorsThatImpactSales")}: ${t("groupingTitle", { groupingName })}`}
            modalWidth="700px"
          />
        </S.GroupingContainer>
      </Card>

      <S.CardsWrapper>
        <ConsolidatedChart predictions={consolidatedPredictions} monthColumns={monthColumns} heading={t("planVsForecast")} />
        <ConsolidatedTable
          predictions={consolidatedPredictions}
          columns={consolidatedForecastColumns()}
          selectedForecast={selectedForecast}
          title={`${t("planVsForecast")} - ${t("monthlyBreakdown")}`}
        />
        <IndexesCard selected={selected} products={products} />
      </S.CardsWrapper>

      <SecondaryChartsSection
        selected={selected}
        predictions={predictions}
        products={products}
        defineProductFilter={defineProductFilter}
      />
    </S.OuterContainer>
  );
}
