import React, { useContext, useEffect, useState } from "react";
import { ForecastContext } from "../../../templates/context/ForecastContext";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { i18n } from "../../../translate/i18n";

import { to2DecimalsNumber, toNDecimalsString } from "../../../utils/utils";
import { getConsolidatedPredictions } from "../../../services/PredictionsService";

import UpperCardModel from "../UpperCardModel/UpperCardModel";

export default function PlanOuterCard() {
  const { t } = i18n;
  const { userInfo } = useContext(CompanyContext);
  const { selectedForecast } = useContext(ForecastContext);

  const [consolidatedPredictions, setConsolidatedPredictions] = useState([]);

  const planVsForecastValue = calculatePlanVsForecast().value;

  const planVsForecast = toNDecimalsString(Math.abs(planVsForecastValue), 0);

  const percentDifference = toNDecimalsString(Math.abs(calculatePlanVsForecast().percentDifference), 2);

  const difference =
    calculatePlanVsForecast().percentDifference > 0 ? { arrow: "↑", word: t("above") } : { arrow: "↓", word: t("below") };

  const companyId = userInfo.company.id;
  const unit = userInfo.company.unit;

  useEffect(() => {
    const token = localStorage.getItem("token");

    getConsolidatedPredictions(token, [selectedForecast.forecastId])
      .then(res => setConsolidatedPredictions(res[0]))
      .catch(err => console.log(err));
  }, []);

  function calculatePlanVsForecast() {
    let monthsCount = 0;
    let sumClientPrediction = 0;
    let sumAidronPrediction = 0;

    consolidatedPredictions.forEach(month => {
      if (month.clientPrediction && month.aidronPrediction) {
        monthsCount += 1;
        sumClientPrediction += month.clientPrediction;
        sumAidronPrediction += month.aidronPrediction;
      }
    });

    const differenceAidronVsClient = sumClientPrediction - sumAidronPrediction;
    return {
      monthsCount: monthsCount,
      value: differenceAidronVsClient,
      percentDifference: (differenceAidronVsClient / sumAidronPrediction) * 100,
    };
  }

  const productCardData = {
    title: t("product"),
    data: [
      {
        heading: t("productPlan"),
        number: `${difference.arrow} ${
          unit === "items" ? `${planVsForecast} ${t("piecesOfClothingAbbreviation")}` : `R$ ${planVsForecast}`
        }`,
        text: `${percentDifference}% ${difference.word} ${t("theForecast")}`,
        link: "",
      },
      {
        heading: t("paretoAnalysis"),
        number: companyId === 4 || companyId === 3 ? 5 : t("comingSoon"),
        numberText: companyId === 4 || companyId === 3 ? `(${toNDecimalsString(Number(3.7), 1)}% ${t("ofTheTotal")})` : "",
        text: t("eightyPercentOfGap"),
        link: "",
      },
    ],
  };

  const channelCardData = {
    title: t("channels"),
    data: [
      {
        heading: t("channelsTarget"),
        number: companyId === 4 ? "↓ R$ 723.421 " : companyId === 3 || companyId === 2 ? t("waitingForData") : t("comingSoon"),
        text: "abaixo do Forecast",
        link: "",
      },
      {
        heading: t("paretoAnalysis"),
        number: companyId === 4 ? "12" : companyId === 3 || companyId === 2 ? t("waitingForData") : t("comingSoon"),
        numberText: companyId === 4 ? `(7% ${t("ofTheTotal")})` : "",
        text: t("eightyPercentOfGap"),
        link: "",
      },
    ],
  };

  return (
    <UpperCardModel
      title={t("currentPlanVsForecast")}
      text={t("monthsSumDifference")}
      leftCardData={productCardData}
      rightCardData={channelCardData}
    />
  );
}
