import React, { useState } from "react";
import { Card, Button } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

import * as S from "./NewTableOptions.style";

import HierarchyColumnsFilter from "../HierarchyColumnsFilter/HierarchyColumnsFilter";
import ArrowModalButton from "../ArrowModalButton/ArrowModalButton";
import ButtonWithModal from "../ButtonWithModal/ButtonWithModal";
import HierarchyFilters from "./HierarchyFilters/HierarchyFilters";
import TableDownloadButton from "../TableDownloadButton/TableDownloadButton";
import FilterButton from "./HierarchyFilters/FilterButton/FilterButton";

export default function NewTableOptions({
  checkedProducts,
  selectedHierarchyIndexes,
  setSelectedHierarchyIndexes,
  freezeColumns,
  setFreezeColumns,
  productIds,
  selectedProductIds,
  setSelectedProductIds,
  renderHistoricFilter,
  renderGraphicAnalysis,
  downloadButton,
  hierarchyColumnsButton,
  selectedLevels,
  setSelectedLevels,
  inputtedExpressions,
  setInputtedExpressions,
}) {
  const { t } = i18n;

  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  return (
    <S.OuterContainer>
      <Card>
        <S.InnerContainer>
          <S.ButtonsContainer>
            <FilterButton showFilters={showFilters} setShowFilters={setShowFilters} appliedFiltersCount={appliedFiltersCount} />
            {downloadButton && <TableDownloadButton fileName="forecast" size="lg" />}
            <S.AnalysisButtonContainer>
              {renderGraphicAnalysis && (
                <ButtonWithModal
                  label={t("analyzeProduct")}
                  disabled={!checkedProducts.length}
                  renderModalContent={renderGraphicAnalysis}
                  size="lg"
                />
              )}
            </S.AnalysisButtonContainer>
          </S.ButtonsContainer>
          <S.ButtonsContainer>
            <S.ArrowButtonContainer>
              <ArrowModalButton label={t("salesHistory")} renderModalContent={renderHistoricFilter} size="lg" />
            </S.ArrowButtonContainer>
            {hierarchyColumnsButton && (
              <Button
                onClick={() => setShowColumnFilters(!showColumnFilters)}
                label=""
                icon="layout"
                type={showColumnFilters ? "filled" : "outlined"}
                size="lg"
              />
            )}
          </S.ButtonsContainer>
        </S.InnerContainer>
      </Card>
      {showColumnFilters && (
        <HierarchyColumnsFilter
          selectedHierarchyIndexes={selectedHierarchyIndexes}
          setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
          freezeColumns={freezeColumns}
          setFreezeColumns={setFreezeColumns}
        />
      )}
      <HierarchyFilters
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        setAppliedFiltersCount={setAppliedFiltersCount}
        showFilters={showFilters}
        selectedLevels={selectedLevels}
        setSelectedLevels={setSelectedLevels}
        inputtedExpressions={inputtedExpressions}
        setInputtedExpressions={setInputtedExpressions}
      />
    </S.OuterContainer>
  );
}
