import React, { useContext } from "react";
import { Select, SelectItem } from "@aidron/aidron-ds";

import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { getMonthName, formatAdjustPlanIndex } from "../../../../utils/utils";
import { i18n } from "../../../../translate/i18n";

import * as S from "./FiltersAndSums.style";

import HierarchyGroupFilter from "../../../../components/HierarchyGroupFilter/HierarchyGroupFilter";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";
import CustomCard from "../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function FiltersAndSums({
  products,
  filteredPredictions,
  selectedGroup,
  setSelectedGroupValue,
  selectedMonthsValue,
  setSelectedMonthsValue,
  originalMonthColumns,
}) {
  const { t } = i18n;
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;

  const overstock = filteredPredictions.reduce((acc, curr) => {
    if (curr.opportunityAdjustment < 0) return acc + Number(curr.opportunityAdjustment);
    else return acc;
  }, 0);

  const increaseInSales = filteredPredictions.reduce((acc, curr) => {
    if (curr.opportunityAdjustment > 0) return acc + Number(curr.opportunityAdjustment);
    else return acc;
  }, 0);

  return (
    <S.OuterContainer>
      <S.FiltersContainer>
        <HierarchyGroupFilter
          label={t("level")}
          selected={selectedGroup.value}
          products={products}
          onChange={setSelectedGroupValue}
          direction="column"
        />
        <S.SelectWrapper>
          <CustomText weight="bold" textAlign="center">
            {t("period")}
          </CustomText>
          <Select readOnly multiselect label="" selected={selectedMonthsValue} handleChange={setSelectedMonthsValue}>
            {originalMonthColumns.map(option => (
              <SelectItem value={option.id} key={option}>
                {`${getMonthName(option.month)} ${option.year.toString()}`}
              </SelectItem>
            ))}
          </Select>
        </S.SelectWrapper>
      </S.FiltersContainer>

      <S.IndexesContainer>
        <CustomCard padding="10px" height="fit-content" boxShadow="0 4px 8px 0 rgb(0, 0, 0, 0.12)">
          <S.IndexBox>
            <CustomHeading color="orange" size="md" margin="0px">
              {formatAdjustPlanIndex(overstock, unit)}
            </CustomHeading>
            <CustomText weight="bold" color="mediumGrey">
              {t("avoidedStockSurplus")}
            </CustomText>
          </S.IndexBox>
        </CustomCard>

        <CustomCard padding="10px" height="fit-content" boxShadow="0 4px 8px 0 rgb(0, 0, 0, 0.12)">
          <S.IndexBox>
            <CustomHeading color="orange" size="md" margin="0px">
              {formatAdjustPlanIndex(increaseInSales, unit)}
            </CustomHeading>
            <CustomText weight="bold" color="mediumGrey">
              {t("increaseInSales")}
            </CustomText>
          </S.IndexBox>
        </CustomCard>
      </S.IndexesContainer>
    </S.OuterContainer>
  );
}
