import React, { useContext } from "react";
import { ForecastContext } from "../../templates/context/ForecastContext";
import { toLocaleDate } from "../../utils/utils";
import { i18n } from "../../translate/i18n";

import SectionHeader from "../SectionHeader/SectionHeader";

export default function ConsolidatedViewHeader() {
  const { t } = i18n;

  const { selectedForecast } = useContext(ForecastContext);
  const { day, month, year } = selectedForecast;

  const generationDate = toLocaleDate(day, month, year);

  const subtitles = [`${t("forecastGeneratedIn")} ${generationDate}`];

  return <SectionHeader heading={t("consolidatedView")} subtitles={subtitles} />;
}
