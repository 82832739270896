import React from "react";
import { Text, Icon } from "@aidron/aidron-ds";
import { i18n } from "../../../../../../../translate/i18n";

import * as S from "./AdjustPlanDriversChart.style";

import ContentLoader from "../../../../../../../components/ContentLoader/ContentLoader";
import ClientFlowSection from "../../../../../../../components/ClientFlowSection/ClientFlowSection";
import CustomText from "../../../../../../../components/StyledComponents/CustomText/CustomText.style";
import AdjustDriversChartSeries from "./AdjustDriversChartSeries/AdjustDriversChartSeries";

export default function AdjustPlanDriversChart({ orderedProductDrivers, clientFlow }) {
  const { t } = i18n;
  return (
    <ContentLoader
      resultCondition={orderedProductDrivers.length}
      contentCondition={orderedProductDrivers?.length}
      renderError={() => <Text>{t("featureComingSoon")}</Text>}
    >
      <S.TitleContainer>
        <CustomText size="15px !important" weight="bold">
          {t("productDrivers")}
        </CustomText>
        <S.Button>
          <Icon icon="help-circle" size={17} />
          <S.TooltipBox>
            <S.Tooltip className="custom-tooltip">
              <Text size="xs">{t("productDriversExplanation")}</Text>
            </S.Tooltip>
          </S.TooltipBox>
        </S.Button>
      </S.TitleContainer>

      <S.OuterChartContainer>
        <S.InnerChartContainer>
          {orderedProductDrivers.map(driver => (
            <AdjustDriversChartSeries series={driver} key={driver.driver} />
          ))}
        </S.InnerChartContainer>
      </S.OuterChartContainer>

      <ClientFlowSection clientFlow={clientFlow} numberSize="sm" />
    </ContentLoader>
  );
}
