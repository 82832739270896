import React from "react";
import { Card, Heading, Progress } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

export default function TableWrapper({ loadingCondition, resultCondition, children }) {
  const { t } = i18n;

  return loadingCondition() ? (
    resultCondition() ? (
      children
    ) : (
      <Card>
        <Heading size="sm">{t("searchError")}</Heading>
      </Card>
    )
  ) : (
    <div className="d-flex justify-content-center align-items-center width-40px-on-progress">
      <Progress circular />
    </div>
  );
}
