import React from "react";
import { Card } from "@aidron/aidron-ds";

import { sumColumn, toNDecimalsString } from "../../../utils/utils";
import { i18n } from "../../../translate/i18n";

import * as S from "./CheckoutSummary.style";
import * as OpportunityStyledIcons from "../../../utils/opportunityTypes/opportunityTypes.style";

import LabelAndNumberView from "../../Home/UpperCardModel/InnerCard/LabelAndNumberView/LabelAndNumberView";

export default function CheckoutSummary({ opportunities }) {
  const { t } = i18n;
  const sum = sumColumn(opportunities, "value");

  return (
    <Card>
      <S.Wrapper>
        <S.Section border>
          <S.CustomHeading size="md" color="grey">
            {t("checkoutSummary").toUpperCase()}
          </S.CustomHeading>
        </S.Section>

        <S.Section>
          <div className="d-flex align-items-center justify-content-center">
            <S.CustomHeading size="sm" color="grey">
              {t("totalOpportunities").toUpperCase()}
            </S.CustomHeading>
            <S.CustomHeading size="lg" color="orange">
              {opportunities.length ? opportunities.length : "0"}
            </S.CustomHeading>
          </div>
        </S.Section>

        <S.Section border>
          <S.ItemsWrapper>
            <S.Item>
              <OpportunityStyledIcons.SaleIcon />
              <LabelAndNumberView
                heading={t("products").toUpperCase()}
                number={`R$ ${toNDecimalsString(sum, 0)}`}
                text={`${opportunities.length} ${
                  opportunities.length === 1 ? t("opportunity").toLowerCase() : t("opportunities").toLowerCase()
                }`}
              />
            </S.Item>
          </S.ItemsWrapper>
        </S.Section>

        <div className="d-flex align-items-center justify-content-center">
          <S.CustomHeading size="md" color="grey" margin>
            {t("total").toUpperCase()}
          </S.CustomHeading>
          <S.CustomHeading size="lg" color="orange">{`R$ ${toNDecimalsString(sum, 0)}`}</S.CustomHeading>
        </div>
      </S.Wrapper>
    </Card>
  );
}
