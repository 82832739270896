import React from "react";
import { Heading } from "@aidron/aidron-ds";

import CustomText from "../../../components/StyledComponents/CustomText/CustomText.style";

export default function OuterCardHeading({ title, text }) {
  return (
    <div className="ms-1 mb-4">
      <Heading size="sm">{title.toUpperCase()}</Heading>
      <CustomText margin="0" size="xs" color="orange">
        {text}
      </CustomText>
    </div>
  );
}
