import styled from "styled-components";

import { ReactComponent as Stock } from "../../assets/image/in-stock.svg";
import { ReactComponent as Sale } from "../../assets/image/profits.svg";

const IconStyling = `
  width: 50px;
  height: 50px;
  fill: #f26f21;
  @media (max-width: 980px) {
    display: none;
  }
`;

const StockIcon = styled(Stock)`
  ${IconStyling}
`;

const SaleIcon = styled(Sale)`
  ${IconStyling}
`;

export { StockIcon, SaleIcon };
