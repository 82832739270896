import React, { useState } from "react";
import { Card, Icon } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";
import { i18n } from "../../../../translate/i18n";

import { createUserEvent } from "../../../../services/UserEventsService";
import { typeDetails } from "../../../../utils/opportunityTypes/opportunityTypes";

import * as S from "./ListItem.style";

import TextAndLabelView from "./TextAndLabelView/TextAndLabelView";
import ProductHierarchyHeading from "../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import OpportunityAccordion from "./OpportunityAccordion/OpportunityAccordion";
import ActionModal from "./ActionModal/ActionModal";
import ButtonWithModal from "../../../../components/ButtonWithModal/ButtonWithModal";
import ReactivateModal from "./ReactivateModal/ReactivateModal";
import CardOutlined from "../../../../components/CardOutlined/CardOutlined";

export default function ListItem({ opportunity, fetchData, accordionButton, reactivateOpportunity, opportunities }) {
  const { t } = i18n;

  const { id, productId, forecastId, type1, value, percent, status, removedDate } = opportunity;

  const [openAccordion, setOpenAccordion] = useState();

  const opportunityDetails = getOpportunityDetails(type1);

  function getOpportunityDetails(type1) {
    return typeDetails.get(type1);
  }

  const statusDetails = new Map([
    ["active", t("active")],
    ["reactivated", t("reactivated")],
    ["checkout", t("checkout")],
  ]);

  const statusText = statusDetails.get(status);

  const formattedRemovedDate = new Date(removedDate);
  const language = localStorage.getItem("i18nextLng");
  const removedDateText = new Intl.DateTimeFormat(language).format(formattedRemovedDate);

  function handleAccordionButton() {
    const token = localStorage.getItem("token");
    if (!openAccordion) {
      createUserEvent(token, {
        event: "Slider Oportunidade",
        page: "Oportunidades",
        productId: productId,
        forecastId: forecastId,
      });
    }
    setOpenAccordion(!openAccordion);
  }

  return (
    <>
      <Card>
        <S.Container sixthColumn>
          <S.ItemImage border>
            {opportunityDetails.icon}
            <ProductHierarchyHeading productId={productId} />
          </S.ItemImage>

          <S.Item textAlign border>
            <TextAndLabelView label={t("opportunity")} labelSize="xs" text={opportunityDetails.title} />
          </S.Item>
          <S.Item textAlign border>
            <TextAndLabelView
              label={t("percent")}
              labelSize="xs"
              heading={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent), 0)}%`}
              contentSize="md"
            />
          </S.Item>
          <S.Item textAlign border>
            <TextAndLabelView
              label={t("opportunitySize")}
              labelSize="xs"
              heading={`R$ ${toNDecimalsString(value, 0)}`}
              contentSize="md"
            />
          </S.Item>
          <S.Item border>
            {!reactivateOpportunity ? (
              <TextAndLabelView label={t("status")} labelSize="xs" text={statusText} textColor="orange" />
            ) : (
              <TextAndLabelView label={t("deletion")} labelSize="xs" text={removedDateText} />
            )}
          </S.Item>
          <S.Item>
            {!reactivateOpportunity ? (
              <ButtonWithModal
                label={`${t("actions")}...`}
                type="outlined"
                modalWidth="650px"
                renderModalContent={({ closeModal }) => (
                  <ActionModal onClose={closeModal} opportunityId={id} fetchData={fetchData} status={status} />
                )}
              />
            ) : (
              <ButtonWithModal
                label={t("reopen")}
                modalHeading={t("wishReopenOpportunity")}
                type="outlined"
                modalWidth="650px"
                renderModalContent={({ closeModal }) => (
                  <ReactivateModal closeModal={closeModal} reactivateOpportunity={() => reactivateOpportunity(id)} />
                )}
              />
            )}
          </S.Item>
        </S.Container>
        {accordionButton && (
          <S.ButtonAccordion onClick={handleAccordionButton} label="">
            <Icon size="16" icon={openAccordion === type1 ? "chevron-up" : "chevron-down"} />
          </S.ButtonAccordion>
        )}
      </Card>
      {openAccordion && (
        <CardOutlined>
          <OpportunityAccordion
            opportunity={opportunity}
            opportunities={opportunities}
            getOpportunityDetails={getOpportunityDetails}
          />
        </CardOutlined>
      )}
    </>
  );
}
