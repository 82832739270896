import React, { useEffect, useState } from "react";
import { Card } from "@aidron/aidron-ds";
import { getOpportunitiesCount } from "../../../services/OpportunityService.js";
import { i18n } from "../../../translate/i18n.js";

import * as S from "./OpportunityCounter.style.js";

import CardContent from "./CardContent/CardContent.jsx";
import LoadingProgress from "../../../components/LoadingProgress/LoadingProgress.jsx";

export default function OpportunityCounter({ forecast, opportunities }) {
  const { t } = i18n;

  const [counter, setCounter] = useState({});

  const opportunitiesInCheckout = opportunities.filter(opportunity => opportunity.status === "checkout");

  const checkoutSum = opportunitiesInCheckout.reduce((acc, curr) => {
    if (curr.status === "checkout") {
      return Number(acc) + Number(curr.value);
    } else return acc;
  }, 0);
  const checkoutSumToDisplay = checkoutSum > 0 ? checkoutSum : 0;

  const checkoutCount = opportunitiesInCheckout.length;
  const checkoutCountToDisplay = checkoutCount > 0 ? checkoutCount : "0";

  const cards = [
    {
      title: t("salesOpportunities"),
      types: ["avoidLoss", "additionalSale"],
    },
    {
      title: t("optimizePurchase"),
      types: ["stock"],
    },
  ];

  useEffect(() => {
    fetchData();
  }, [opportunities]);

  async function fetchData() {
    if (forecast.forecastId) {
      const token = localStorage.getItem("token");
      const status = ["active", "reactivated"];
      const result = await getOpportunitiesCount(token, forecast.forecastId, status);
      setCounter(result);
    }
  }

  function getTypeData(type) {
    const value = counter.counter?.reduce((acc, curr) => {
      if (type.types.includes(curr.type)) {
        return acc + curr.totalValue;
      } else return acc;
    }, 0);
    const valueToDisplay = value > 0 ? value : 0;

    const count = counter.counter?.reduce((acc, curr) => {
      if (type.types.includes(curr.type)) {
        return acc + curr.count;
      } else return acc;
    }, 0);
    const countToDisplay = count > 0 ? count : "0";

    return { valueToDisplay, countToDisplay };
  }

  return counter.count ? (
    <S.Container>
      <Card>
        <CardContent
          label={t("totalOpportunities")}
          number={counter.totalValue}
          legendNumber={counter.count}
          numberColor="orange"
        />
      </Card>
      <LoadingProgress condition={opportunities.length}>
        <Card>
          <S.CardWrapper>
            {cards.map(type => (
              <CardContent
                label={type.title}
                number={getTypeData(type)?.valueToDisplay}
                legendNumber={getTypeData(type)?.countToDisplay}
                numberColor="orange"
                key={type.title}
              />
            ))}
          </S.CardWrapper>
        </Card>
      </LoadingProgress>
      <Card>
        <CardContent
          label={t("inCheckout")}
          number={Math.floor(checkoutSumToDisplay)}
          legendNumber={checkoutCountToDisplay}
          numberColor="orangeGrey"
          linkTo={checkoutCount > 0 ? "/acoes/checkout" : ""}
        />
      </Card>
    </S.Container>
  ) : (
    <></>
  );
}
