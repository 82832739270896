import React, { useEffect, useState } from "react";

import { getOpportunities } from "../../services/OpportunityService";
import { getForecasts } from "../../services/ForecastService";
import { createUserEvent } from "../../services/UserEventsService";
import { i18n } from "../../translate/i18n";

import * as S from "./Checkout.style";

import Default from "../../components/Default/Default";
import OpportunityList from "./OpportunityList/OpportunityList";
import CheckoutSummary from "./CheckoutSummary/CheckoutSummary";
import ProductSummary from "./ProductSummary/ProductSummary";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";
import OpportunityOptions from "../Opportunity/OpportunityOptions/OpportunityOptions";
import NewContentLoader from "../../components/NewContentLoader/NewContentLoader";
import Error from "./Error/Error";
import ResultError from "../../components/ResultError/ResultError";

export default function Checkout() {
  const { t } = i18n;

  const [forecast, setForecast] = useState({});
  const [opportunities, setOpportunities] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [types, setTypes] = useState([]);
  const [order, setOrder] = useState("descending");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");

  const selected = opportunities.find(opportunity => opportunity.id === selectedId);

  const status = ["checkout"];

  const allTypeOptions = [
    {
      value: "additionalSale",
      displayName: t("additionalSale"),
    },
    {
      value: "avoidLoss",
      displayName: t("avoidSalesLoss"),
    },
    {
      value: "stock",
      displayName: t("stockOptimization"),
    },
  ];

  const typeOptions = allTypeOptions.filter(type => types.includes(type.value));

  function onOpportunityClick(id) {
    if (id === selectedId) {
      setSelectedId(0);
    } else {
      setSelectedId(id);
    }
  }

  useEffect(() => {
    fetchForecast();
    createUserEvent(token, { event: "Página Checkout", page: "Checkout" });
  }, []);

  useEffect(() => {
    fetchFiltersData();
  }, [forecast]);

  useEffect(() => {
    fetchOpportunities();
  }, [selectedProductIds, selectedTypes, order]);

  async function fetchForecast() {
    const latestForecast = await getForecasts(token, "opportunities");
    setForecast(latestForecast[0]);
  }

  async function fetchFiltersData() {
    if (forecast.forecastId) {
      const result = await getOpportunities(token, forecast.forecastId, selectedProductIds, order, selectedTypes, status);

      if (!result.productIds.length) {
        setError(true);
      }

      setProductIds(result.productIds);
      setSelectedProductIds(result.productIds);

      const secondResult = await getOpportunities(token, forecast.forecastId, result.productIds, order, selectedTypes, status);
      setTypes(secondResult.types);
      setSelectedTypes(secondResult.types);
    }
  }

  async function fetchOpportunities() {
    if (forecast.forecastId) {
      const result = await getOpportunities(token, forecast.forecastId, selectedProductIds, order, selectedTypes, status);

      setLoading(false);
      setSelectedId(0);
      setOpportunities(result.opportunities);
    }
  }

  function showCheckoutSummary() {
    return selectedId === 0 || opportunities.length === 0;
  }

  return (
    <Default>
      <PageHeading>{t("opportunitiesCheckout")}</PageHeading>
      <S.Grid>
        <div>
          <NewContentLoader loading={loading} results={!error} renderError={<Error />}>
            <OpportunityOptions
              productIds={productIds}
              selectedProductIds={selectedProductIds}
              setSelectedProductIds={setSelectedProductIds}
              order={order}
              setOrder={setOrder}
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              typeOptions={typeOptions}
              fetchData={fetchFiltersData}
            />
            <NewContentLoader loading={loading} results={opportunities.length} renderError={<ResultError />}>
              <OpportunityList
                opportunities={opportunities}
                onItemClick={onOpportunityClick}
                selectedId={selectedId}
                fetchData={fetchFiltersData}
              />
            </NewContentLoader>
          </NewContentLoader>
        </div>

        {showCheckoutSummary() ? (
          <CheckoutSummary opportunities={opportunities} />
        ) : (
          <ProductSummary opportunity={selected} fetchData={fetchFiltersData} />
        )}
      </S.Grid>
    </Default>
  );
}
