import React from "react";
import { i18n } from "../../translate/i18n";

import * as S from "./CheckItem.style";

import ModalCheck from "../ModalCheck/ModalCheck";
import ActionRadio from "./ActionRadio/ActionRadio";

export default function CheckItem() {
  const { t } = i18n;
  return (
    <S.ItemCheck textAlign>
      <span>{t("actions")}...</span>
      <ModalCheck renderModalContent={({ closeModal }) => <ActionRadio onClose={closeModal} />} />
    </S.ItemCheck>
  );
}
