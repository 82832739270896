import React from "react";
import { Text } from "@aidron/aidron-ds";
import MonthHeaderCells from "../MonthHeaderCells/MonthHeaderCells";
import { i18n } from "../../translate/i18n";

export default function ColumnTitlesRow({
  freezeColumns,
  showSeasonColumn,
  monthColumns,
  monthHeaderCellHeight,
  setMonthHeaderCellHeight,
  setMonthColumnsWidth,
  historicMonthsToDisplay,
  wape,
  monthHeaderCellsSpan,
  defineHierarchyToDisplay,
  currentMonth,
  defineLeftCellBorder,
  renderColumnTitleLabel,
  thirdSectionColumns,
  renderThirdColumnTitleLabel,
  productCellRowSpan,
  title,
}) {
  const { t } = i18n;

  function defineProductsTitleSpan() {
    let columnsSpan = defineHierarchyToDisplay().length;
    if (!productCellRowSpan) columnsSpan += 1;
    if (showSeasonColumn) columnsSpan += 1;
    return columnsSpan;
  }

  return (
    <tr>
      <th
        colSpan={defineProductsTitleSpan()}
        rowSpan={productCellRowSpan ? productCellRowSpan : 1}
        className={`background-color-white vertical-align-baseline z-5 first-row ${
          freezeColumns ? "right-border-fade" : "cell-right-border"
        }`}
        style={{ left: freezeColumns ? "0" : "" }}
      >
        {defineHierarchyToDisplay().length === 0 ? <div></div> : <Text className="text-center font-size-16px">{title}</Text>}
      </th>
      {historicMonthsToDisplay && historicMonthsToDisplay.length !== 0 ? (
        <th colSpan={historicMonthsToDisplay?.length * 2} className="text-center z-1">
          <Text className="font-size-16px">{t("salesHistory")}</Text>
        </th>
      ) : (
        <></>
      )}
      <MonthHeaderCells
        monthColumns={monthColumns}
        monthHeaderCellHeight={monthHeaderCellHeight}
        setMonthHeaderCellHeight={setMonthHeaderCellHeight}
        setMonthColumnsWidth={setMonthColumnsWidth}
        wape={wape}
        monthHeaderCellsSpan={monthHeaderCellsSpan}
        defineLeftCellBorder={defineLeftCellBorder}
        currentMonth={currentMonth}
        historicMonthsToDisplay={historicMonthsToDisplay}
        renderColumnTitleLabel={renderColumnTitleLabel}
        thirdSectionColumns={thirdSectionColumns}
        renderThirdColumnTitleLabel={renderThirdColumnTitleLabel}
      />
    </tr>
  );
}
