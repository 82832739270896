import React, { memo } from "react";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import { Button } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

import MyAccountSettingsTab from "./MyAccountSettingsTab/MyAccountSettingsTab";
import OrganizationSettingsTab from "./OrganizationSettingsTab/OrganizationSettingsTab";
import UsersSettingsTab from "./UsersSettingsTab/UsersSettingsTab";
import Default from "../../components/Default/Default";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

function Settings() {
  const { t } = i18n;

  const tabs = [
    { name: t("myAccount"), route: "minhaConta" },
    // { name: "Usuários", route: "usuarios" },
    // { name: "Organização", route: "organizacao" },
  ];
  const location = useLocation();

  function defineTabButtonStyle(route) {
    return location.pathname === `/configuracoes/${route}` ? "filled" : "outlined";
  }

  function defineBorderStyle(route) {
    return location.pathname === `/configuracoes/${route}` ? "" : "border-bottom-0";
  }

  return (
    <Default>
      <PageHeading>{t("settings")}</PageHeading>
      <div className="border-bottom-orange d-flex mt-4">
        {tabs.map((tab, index) => (
          <Link to={`/configuracoes/${tab.route}`} key={index}>
            <Button
              label={i18n.t(tab.name)}
              onClick={() => {}}
              type={defineTabButtonStyle(tab.route)}
              className={`border-radius-15px-150px-0-0 box-shadow-black-2--2-5-0 box-shadow-black-1--3-6-on-button-hover border-bottom-0 ms-3 ${defineBorderStyle(
                tab.route
              )}`}
            />
          </Link>
        ))}
      </div>
      <Switch>
        <Route path="/configuracoes/minhaConta">
          <MyAccountSettingsTab />
        </Route>
        <Route path="/configuracoes/usuarios">
          <UsersSettingsTab />
        </Route>
        <Route path="/configuracoes/organizacao">
          <OrganizationSettingsTab />
        </Route>
      </Switch>
    </Default>
  );
}

export default memo(Settings);
