import styled from "styled-components";
import { Button } from "@aidron/aidron-ds";

const StyledButton = styled(Button)`
  background-color: transparent !important;
  border: unset !important;

  &,
  &.button-primary-medium:hover {
    box-shadow: none !important;
  }

  &.button.button-filled.button-primary-medium:focus {
    background-color: unset;
    border: unset;
  }

  svg {
    color: ${({ color }) => (color ? color : "")};
  }
`;

export { StyledButton };
