import React, { useEffect, useState } from "react";
import { Button, Text } from "@aidron/aidron-ds";

import { getForecasts } from "../../../../../services/ForecastService";
import { getOpportunities } from "../../../../../services/OpportunityService";
import { i18n } from "../../../../../translate/i18n";

import ModalTemplate from "../../../../../components/ModalTemplate/ModalTemplate";

export default function OpportunityButton({ productId }) {
  const { t } = i18n;

  const [disable, setDisable] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const forecasts = await getForecasts(token, "opportunities");
    const lastForecast = forecasts[0];

    if (lastForecast) {
      const order = "any";
      const selectedTypes = "";
      const status = ["active", "reactivated", "checkout"];
      const opportunities = await getOpportunities(token, lastForecast.forecastId, [productId], order, selectedTypes, status);

      if (opportunities.opportunities.length) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  }

  function handleClick() {
    const url = `/oportunidades/produtos?productId=${encodeURIComponent(productId)}`;
    window.open(url, "_blank");
  }

  return (
    <>
      <Button
        label={t("activeOpportunities")}
        icon="arrow-up-right"
        position="right"
        onClick={handleClick}
        type="outlined"
        disabled={disable}
      />
      {openModal && (
        <ModalTemplate width="500px" heading="Em breve!" closingFunction={() => setOpenModal(false)}>
          <Text>{t("possibleUpcomingFeature")}</Text>
        </ModalTemplate>
      )}
    </>
  );
}
