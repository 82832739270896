import React from "react";
import { Card, Text } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";

import * as S from "./ActionOuterCard.style";
import SimulatorIcon from "../../../assets/image/simulacoes-icone.png";

import OuterCardHeading from "../OuterCardHeading/OuterCardHeading";

export default function ActionOuterCard() {
  const { t } = i18n;
  return (
    <S.OuterWrapper>
      <Card style={{ height: "100%" }}>
        <OuterCardHeading title={t("simulateActions")} text={t("simulateActionsSubtitle")} />
        <S.InnerWrapper>
          <S.ContentContainer>
            <S.Icon src={SimulatorIcon} />
            <Text>{t("exploreScenarios")}</Text>
          </S.ContentContainer>
        </S.InnerWrapper>
      </Card>
    </S.OuterWrapper>
  );
}
