import React, { useContext, useState } from "react";
import { NavBar, Icon } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { i18n } from "../../../translate/i18n";

import * as S from "./Header.style";

import HeaderMenu from "../../HeaderMenu/HeaderMenu";
import Calendar from "../../../pages/Calendar/Calendar";
import AccountHeaderMenu from "../../AccountHeaderMenu/AccountHeaderMenu";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";

export default function Header() {
  const { t } = i18n;
  const { userInfo } = useContext(CompanyContext);
  const { company } = userInfo;
  const [showCalendar, setShowCalendar] = useState(false);

  const menuList = [
    {
      menuName: "Home",
      route: "/home",
    },
    {
      menuName: t("opportunities"),
      route: "/oportunidades",
      pages: [
        {
          pageName: t("productOpportunities"),
          route: "/produtos",
        },
        {
          pageName: t("channelOpportunities"),
          route: company.id === 4 ? "/canais" : "",
        },
        {
          pageName: t("marketInsights"),
          route: company.id === 4 ? "/mercado" : "",
        },
      ],
    },
    {
      menuName: t("analysis"),
      route: "/analise",
      pages: [
        { pageName: t("productPlanVsForecast"), route: "/plano" },
        {
          pageName: t("channelsTargetVsForecast"),
          route: company.id === 4 ? "/canais" : t("waitingForData"),
        },
        { pageName: t("forecastAccuracy"), route: "/assertividadePlano" },
      ],
    },
    {
      menuName: t("actions"),
      route: "/acoes",
      pages: [
        { pageName: t("adjustProductPlan"), route: company.id === 2 ? "/ajustarPlano" : "" },
        { pageName: t("opportunitiesCheckout"), route: "/checkout" },
        { pageName: t("simulateActions"), route: "" },
      ],
    },
  ];

  return (
    <NavBar home>
      <S.LogoContainer>
        <img src="/img/logo-aidron-branco.png" alt="Aidron" className="logo" />
      </S.LogoContainer>
      <S.MenuContainer>
        {menuList.map((item, index) => (
          <HeaderMenu item={item} key={index} />
        ))}
      </S.MenuContainer>

      <S.ButtonsContainer>
        <S.CalendarButton onClick={() => setShowCalendar(true)}>
          <Icon icon="calendar" size={30} />
        </S.CalendarButton>
        <LanguageSelector />
      </S.ButtonsContainer>

      <AccountHeaderMenu />
      {showCalendar && <Calendar setShowCalendar={setShowCalendar} />}
    </NavBar>
  );
}
