import React from "react";
import { toNDecimalsString } from "../../../../../../utils/utils";
import { i18n } from "../../../../../../translate/i18n";

import * as S from "./ProductAccordion.style";

import CustomCard from "../../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import OpportunityChart from "../../../../../Opportunity/ProductListItems/ListItem/OpportunityAccordion/OpportunityChart/OpportunityChart";
import CustomText from "../../../../../../components/StyledComponents/CustomText/CustomText.style";
import ProductDriversData from "../../../../../../components/ProductDriversData/ProductDriversData";
import AdjustPlanDriversChart from "./AdjustPlanDriversChart/AdjustPlanDriversChart";
import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import SumCards from "./SumCards/SumCards";

export default function ProductAccordion({ forecastId, productId, prediction, columnsCount, predictions }) {
  const { t } = i18n;
  const language = localStorage.getItem("i18nextLng");

  function includePercentSymbol(value) {
    return `${toNDecimalsString(value, 1).toLocaleString(language)}%`;
  }

  function includePlusOrMinusAndPercent(value) {
    if (value == 0 || (value >= -0.04 && value <= 0.04)) {
      return "0%";
    } else {
      const plusOrMinus = value > 0 ? "+" : "-";
      return `${plusOrMinus} ${includePercentSymbol(Math.abs(value))}`;
    }
  }
  return (
    <S.TableRow>
      <td colSpan={columnsCount}>
        <S.Container>
          <CustomCard boxShadow="unset" padding="0px 16px 16px 16px" backgroundColor="mediumLightGrey">
            <S.ChartsRow>
              <S.Column>
                <S.FirstColumn>
                  <ProductHierarchyHeading productId={productId} />
                  <S.SmallCardsContainer>
                    <SumCards predictions={predictions} productId={productId} />
                  </S.SmallCardsContainer>
                </S.FirstColumn>
              </S.Column>

              <S.Column>
                <CustomCard padding="5px 10px" margin="20px 0 0 0">
                  <CustomText weight="bold" textAlign="center" size="15px !important">
                    {t("clientDemandVsPlan")}
                  </CustomText>
                  <S.InnerChartContainer>
                    <OpportunityChart opportunity={{ forecastId, productId }} hideProductHierarchy />
                  </S.InnerChartContainer>
                </CustomCard>
              </S.Column>

              <S.Column>
                <S.ProductDriversColumn>
                  <CustomCard padding="10px" height="fit-content" margin="20px 0 0 0">
                    <S.ProductDriversChart>
                      <ProductDriversData productId={productId}>
                        <AdjustPlanDriversChart />
                      </ProductDriversData>
                    </S.ProductDriversChart>
                  </CustomCard>

                  <S.Indexes>
                    <CustomCard padding="5px 10px">
                      <S.IndexContainer>
                        <S.Index>
                          <div>
                            <CustomText weight="bold" textAlign="center" size="12px !important" whiteSpace="break-spaces">
                              {t("salesProblemProbability")}
                            </CustomText>
                          </div>
                          <CustomText weight="bold" textAlign="center" size="14px !important" color="orange">
                            {includePercentSymbol(prediction?.saleAnomalyProbability)}
                          </CustomText>
                        </S.Index>
                      </S.IndexContainer>
                    </CustomCard>

                    <CustomCard padding="5px 10px">
                      <S.IndexContainer>
                        <S.Index>
                          <CustomText weight="bold" textAlign="center" size="12px !important" whiteSpace="break-spaces">
                            {t("differenceAIAccuracyVsPlan")}
                          </CustomText>
                          <CustomText weight="bold" textAlign="center" size="14px !important" color="orange">
                            {includePlusOrMinusAndPercent(prediction?.aiAccuracyOverClient)}
                          </CustomText>
                        </S.Index>
                      </S.IndexContainer>
                    </CustomCard>
                  </S.Indexes>
                </S.ProductDriversColumn>
              </S.Column>
            </S.ChartsRow>
          </CustomCard>
        </S.Container>
      </td>
    </S.TableRow>
  );
}
