import React, { useState } from "react";
import { Prompt } from "react-router-dom/cjs/react-router-dom";
import { Heading, TextField, Button } from "@aidron/aidron-ds";
import { updateUser } from "../../../../services/UsersService";
import { i18n } from "../../../../translate/i18n";

import ModalTemplate from "../../../../components/ModalTemplate/ModalTemplate";
import PasswordValidation from "./PasswordValidation/PasswordValidation";

function ChangePassword() {
  const { t } = i18n;

  const [newPassword, setNewPassword] = useState({});
  const [resultMessage, setResultMessage] = useState("");

  function hasCapitalLetter() {
    return /[A-Z]/.test(newPassword.first);
  }

  function hasLowercaseLetter() {
    return /[a-z]/.test(newPassword.first);
  }

  function hasNumber() {
    return /\d/.test(newPassword.first);
  }

  function hasSpecialCharacter() {
    return /[!@#$%^&*(),.?":{}|<>]/.test(newPassword.first);
  }

  function hasMinimumLength() {
    return newPassword.first?.length >= 6;
  }

  function arePasswordsEqual() {
    return newPassword.first && newPassword.first === newPassword.second;
  }

  function isPasswordSecure() {
    return hasCapitalLetter() && hasLowercaseLetter() && hasNumber() && hasSpecialCharacter() && hasMinimumLength();
  }

  function isPasswordValid() {
    return arePasswordsEqual() && isPasswordSecure(newPassword.first);
  }

  async function saveNewPassword() {
    try {
      const token = localStorage.getItem("token");
      await updateUser(token, { password: newPassword.first });

      setNewPassword({});
      setResultMessage(t("newPasswordSaved"));
    } catch (error) {
      setResultMessage(t("newPasswordNotSaved"));
      console.log(error);
    }
  }

  function areInputsModified() {
    return (newPassword.first && newPassword.first !== "") || (newPassword.second && newPassword.second !== "");
  }

  return (
    <>
      <Prompt when={areInputsModified() ? true : false} message={t("unsavedChangesModal.promptBody")} />
      <div className="mt-3">
        <Heading size="sm">{t("changePassword")}</Heading>
        <div className="d-flex">
          <div className="me-3">
            <div className="mt-3">
              <TextField
                onChange={e => setNewPassword({ ...newPassword, first: e })}
                type="password"
                label={t("newPassword")}
                placeholder=""
                value={newPassword.first}
              />
            </div>
            <div className="mt-3">
              <TextField
                onChange={e => setNewPassword({ ...newPassword, second: e })}
                type="password"
                label={t("repeatNewPassword")}
                placeholder=""
                value={newPassword.second}
              />
            </div>
          </div>
          {newPassword.first && (
            <PasswordValidation
              newPassword={newPassword}
              hasCapitalLetter={hasCapitalLetter}
              hasLowercaseLetter={hasLowercaseLetter}
              hasNumber={hasNumber}
              hasSpecialCharacter={hasSpecialCharacter}
              hasMinimumLength={hasMinimumLength}
              arePasswordsEqual={arePasswordsEqual}
            />
          )}
        </div>
        <Button label={t("savePassword")} onClick={saveNewPassword} disabled={!isPasswordValid()} className="mt-3" />
      </div>
      {resultMessage && <ModalTemplate width="500px" heading={resultMessage} closingFunction={() => setResultMessage("")} />}
    </>
  );
}

export default ChangePassword;
