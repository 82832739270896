import React, { useState } from "react";
import { Button } from "@aidron/aidron-ds";
import { updatePredictions } from "../../../services/PredictionsService";
import { i18n } from "../../../translate/i18n";

import * as S from "./AdjustPlanTableOptions.style";

import HierarchyColumnsFilter from "../../../components/HierarchyColumnsFilter/HierarchyColumnsFilter";
import ButtonWithModal from "../../../components/ButtonWithModal/ButtonWithModal";
import HierarchyFilters from "../../../components/NewTableOptions/HierarchyFilters/HierarchyFilters";
import TableDownloadButton from "../../../components/TableDownloadButton/TableDownloadButton";
import FilterButton from "../../../components/NewTableOptions/HierarchyFilters/FilterButton/FilterButton";
import CustomHeading from "../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../components/StyledComponents/CustomText/CustomText.style";
import CustomCard from "../../../components/StyledComponents/CustomCard/CustomCard.style";
import AIButton from "./AIButton/AIButton";

export default function AdjustPlanTableOptions({
  selectedHierarchyIndexes,
  setSelectedHierarchyIndexes,
  freezeColumns,
  setFreezeColumns,
  productIds,
  selectedProductIds,
  setSelectedProductIds,
  selectedLevels,
  setSelectedLevels,
  inputtedExpressions,
  setInputtedExpressions,
  showInputs,
  setShowInputs,
  inputs,
  fetchPredictions,
  saveLoading,
  setSaveLoading,
  setInputs,
  filteredPredictions,
}) {
  const { t } = i18n;

  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [onlyAIPredictions, setOnlyAIPredictions] = useState(false);

  async function onInputsSave() {
    setSaveLoading(true);
    const token = localStorage.getItem("token");

    await updatePredictions(token, inputs);
    await fetchPredictions();
    setShowInputs(false);
    setInputs([]);
    setSaveLoading(false);
    setInputtedExpressions([]);
    setSelectedLevels([]);
  }

  return (
    <S.OuterContainer>
      <CustomCard padding="9px 16px">
        <S.InnerContainer>
          <S.ButtonsContainer>
            <CustomHeading size="sm" margin="0">
              {t("newPlanAnalysis")}
            </CustomHeading>
            <FilterButton
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              appliedFiltersCount={appliedFiltersCount}
              size="sm"
            />
            <Button
              onClick={() => setShowColumnFilters(!showColumnFilters)}
              label=""
              icon="layout"
              type={showColumnFilters ? "filled" : "outlined"}
              size="sm"
            />
            <AIButton onlyAIPredictions={onlyAIPredictions} setOnlyAIPredictions={setOnlyAIPredictions} />
          </S.ButtonsContainer>
          <S.ButtonsContainer>
            <Button
              onClick={() => {
                setShowInputs(!showInputs);
                setInputs([]);
              }}
              label={showInputs ? t("cancelEdit") : t("edit")}
            />
            {saveLoading ? (
              <CustomText color="orange" weight="bold">
                {t("saving")}...
              </CustomText>
            ) : (
              <Button onClick={() => onInputsSave()} label={t("save")} type="outlined" disabled={!showInputs} />
            )}
          </S.ButtonsContainer>
          <S.ButtonsContainer>
            <TableDownloadButton fileName={t("adjustSalesPlan")} size="sm" />
            <ButtonWithModal
              label={t("integrate")}
              type="filled"
              modalWidth="600px"
              renderModalContent={() => (
                <CustomHeading size="sm" textAlign="center" margin="20px">
                  {t("possibleUpcomingFeature")}
                </CustomHeading>
              )}
            />
          </S.ButtonsContainer>
        </S.InnerContainer>
      </CustomCard>
      {showColumnFilters && (
        <HierarchyColumnsFilter
          selectedHierarchyIndexes={selectedHierarchyIndexes}
          setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
          freezeColumns={freezeColumns}
          setFreezeColumns={setFreezeColumns}
        />
      )}
      <HierarchyFilters
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        setAppliedFiltersCount={setAppliedFiltersCount}
        showFilters={showFilters}
        selectedLevels={selectedLevels}
        setSelectedLevels={setSelectedLevels}
        inputtedExpressions={inputtedExpressions}
        setInputtedExpressions={setInputtedExpressions}
        onlyAIPredictions={onlyAIPredictions}
        filteredPredictions={filteredPredictions}
      />
    </S.OuterContainer>
  );
}
