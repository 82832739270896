import React, { useState } from "react";
import { Card, Button } from "@aidron/aidron-ds";

import * as S from "./OpportunityOptions.style";

import HierarchyFilters from "../../../components/NewTableOptions/HierarchyFilters/HierarchyFilters";
import SortSelect from "../../../components/SortSelect/SortSelect";
import ArrowModalButton from "../../../components/ArrowModalButton/ArrowModalButton";
import OpportunityTypeFilter from "./OpportunityTypeFilter/OpportunityTypeFilter";

export default function OpportunityOptions({
  productIds,
  selectedProductIds,
  setSelectedProductIds,
  order,
  setOrder,
  selectedTypes,
  setSelectedTypes,
  typeOptions,
}) {
  const [showFilters, setShowFilters] = useState(false);

  const orderOptions = [
    {
      value: "descending",
      displayName: "maior valor",
    },
    {
      value: "ascending",
      displayName: "menor valor",
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "25px" }}>
        <S.wrapper>
          <Button
            onClick={() => setShowFilters(!showFilters)}
            label=""
            icon="filter"
            type={showFilters ? "filled" : "outlined"}
            size="lg"
            className="me-3"
          />
          <ArrowModalButton
            label="Tipo de Oportunidade"
            renderModalContent={({ closeModal }) => (
              <OpportunityTypeFilter
                selectedTypes={selectedTypes}
                setSelectedTypes={setSelectedTypes}
                onConcludedClick={closeModal}
                typeOptions={typeOptions}
              />
            )}
          />
          <SortSelect label="Ordenar por:" selected={order} options={orderOptions} onChange={setOrder} />
        </S.wrapper>
      </Card>

      {showFilters && (
        <HierarchyFilters
          productIds={productIds}
          selectedProductIds={selectedProductIds}
          setSelectedProductIds={setSelectedProductIds}
        />
      )}
    </>
  );
}
