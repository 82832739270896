import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getForecasts } from "../../../services/ForecastService";
import { getOpportunitiesCount } from "../../../services/OpportunityService";
import { toNDecimalsString } from "../../../utils/utils";
import { i18n } from "../../../translate/i18n";

import UpperCardModel from "../UpperCardModel/UpperCardModel";

export default function OpportunityOuterCard() {
  const { t } = i18n;
  const { userInfo } = useContext(CompanyContext);
  const companyId = userInfo.company.id;

  const [counter, setCounter] = useState({});

  const opportunitiesTotal = isNumber(counter.totalValue) ? toNDecimalsString(counter.totalValue, 0) : 0;

  const stockOpportunity = counter.counter?.find(obj => obj.type === "stock");
  const stockTotal = isNumber(stockOpportunity?.totalValue) ? toNDecimalsString(stockOpportunity?.totalValue, 0) : "0";

  useEffect(() => {
    fetchInitialData();
  }, []);

  async function fetchInitialData() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "opportunities");
    const latestForecast = forecasts[forecasts.length - 1];

    const status = ["active", "reactivated", "checkout"];
    const result = await getOpportunitiesCount(token, latestForecast.forecastId, status);
    setCounter(result);
  }

  function isNumber(number) {
    return /\d/.test(number);
  }

  const productCardData = {
    title: t("product"),
    data: [
      {
        heading: t("totalOpportunities"),
        number: opportunitiesTotal ? `R$ ${opportunitiesTotal}` : t("comingSoon"),
        text: t("addingStockAndSales"),
        link: "",
      },
      {
        heading: t("stockOptimization"),
        number: stockTotal ? `R$ ${stockTotal}` : t("comingSoon"),
        text: t("costReduction"),
        link: "",
      },
    ],
  };

  const channelCardData = {
    title: t("channels"),
    data: [
      {
        heading: t("totalOpportunities"),
        number: companyId === 4 ? "R$ 12.123.421" : companyId === 3 ? t("comingSoon") : t("comingSoon"),
        text: t("adjustingPlanAndStock"),
        link: "",
      },
      {
        heading: t("assortmentAdjustment"),
        number: companyId === 4 ? "R$ 8.952.012" : companyId === 3 ? t("comingSoon") : t("comingSoon"),
        text: t("additionalSale").toLowerCase(),
        link: "",
      },
    ],
  };

  return (
    <UpperCardModel
      title={t("opportunities")}
      text={t("stockOrSalesIncreaseOptimization")}
      leftCardData={productCardData}
      rightCardData={channelCardData}
    />
  );
}
