import React, { useContext } from "react";
import { Select, SelectItem, Card, Text, Switch } from "@aidron/aidron-ds";
import { CompanyContext } from "../../templates/context/CompanyContext";
import { i18n } from "../../translate/i18n";

export default function HierarchyColumnsFilter({
  selectedHierarchyIndexes,
  setSelectedHierarchyIndexes,
  freezeColumns,
  setFreezeColumns,
}) {
  const { t } = i18n;

  const { hierarchy } = useContext(CompanyContext);

  function handleChange(options) {
    if (options.length > 0) setSelectedHierarchyIndexes(options);
  }

  return (
    <Card
      style={{
        margin: "10px 0",
        padding: "10px 20px 10px 30px",
        backgroundColor: "rgb(252, 252, 252)",
      }}
    >
      <div className="margin-on-switch-outer">
        <Switch handleChange={option => setFreezeColumns(option)} value={freezeColumns}>
          {t("freezeColumns")}
        </Switch>
      </div>
      <Text size="sm" weight="bold">
        {t("choseColumns")}
      </Text>
      <div className="mb-2 mx-2">
        <Select readOnly multiselect label="" handleChange={handleChange} selected={selectedHierarchyIndexes}>
          {hierarchy.levels.map((option, index) => (
            <SelectItem key={index} value={index}>
              {hierarchy.labels[option]}
            </SelectItem>
          ))}
        </Select>
      </div>
    </Card>
  );
}
