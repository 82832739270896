import React, { useContext, useEffect, useState } from "react";
import { Text, Button } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { i18n } from "../../../translate/i18n";

import * as S from "./HierarchyFilters.style";

import SearchInput from "../../SearchInput/SearchInput";
import HierarchyFilterBox from "./HierarchyFilterBox/HierarchyFilterBox";
import CustomCard from "../../../components/StyledComponents/CustomCard/CustomCard.style";

export default function HierarchyFilters({
  productIds,
  selectedProductIds,
  setSelectedProductIds,
  setAppliedFiltersCount,
  showFilters,
  selectedLevels,
  setSelectedLevels,
  inputtedExpressions,
  setInputtedExpressions,
  onlyAIPredictions,
  filteredPredictions,
}) {
  const { t } = i18n;

  const { hierarchy, allProducts } = useContext(CompanyContext);

  const [typedExpression, setTypedExpression] = useState("");

  useEffect(() => {
    let filteredProductIds = productIds;

    if (isAnyHierarchySelected()) {
      filteredProductIds = filterProductsByHierarchy().map(product => product.productId);
    }

    if (inputtedExpressions.length) {
      const productsIdsByExpression = getProductIdsByExpression();
      filteredProductIds = filteredProductIds.filter(productId => productsIdsByExpression.includes(productId));
    }

    if (onlyAIPredictions) {
      const opportunityAdjustmentsPredictions = filteredPredictions.filter(prediction => prediction.opportunityAdjustment);
      const allAIProductIds = opportunityAdjustmentsPredictions.map(prediction => prediction.productId);
      const AIProductIds = [...new Set(allAIProductIds)];
      filteredProductIds = filteredProductIds.filter(productId => AIProductIds.includes(productId));
    }

    setSelectedProductIds(filteredProductIds);
    setAppliedFiltersCount(appliedFiltersNumber());
  }, [selectedLevels, inputtedExpressions, onlyAIPredictions]);

  function isAnyHierarchySelected() {
    const selectedHierarchies = Object.values(selectedLevels);
    if (selectedHierarchies.some(selected => selected.length)) {
      return true;
    }
    return false;
  }

  function filterProductsByHierarchy() {
    let filteredProducts = productIds.map(productId => allProducts.find(product => product.productId === productId));
    filteredProducts = filteredProducts.filter(product => product);
    for (const level in selectedLevels) {
      if (selectedLevels[level].length) {
        filteredProducts = filteredProducts.filter(product => {
          return selectedLevels[level].includes(product[level]);
        });
      }
    }
    return filteredProducts;
  }

  // Hoje estamos permitindo que o usuário só digite uma expressão por vez, então uso aqui inputtedExpressions[0]
  function getProductIdsByExpression() {
    const expression = inputtedExpressions[0].toUpperCase();
    const filteredByExpression = allProducts.filter(product => {
      return hierarchy.levels.some(level => product[level].toUpperCase().includes(expression));
    });
    return filteredByExpression.map(product => product.productId);
  }

  function appliedFiltersNumber() {
    let count = 0;
    if (isAnyHierarchySelected()) count += 1;
    if (inputtedExpressions.length) count += 1;
    return count;
  }

  function isAnyFilterApplied() {
    return appliedFiltersNumber() !== 0;
  }

  function resetFilters() {
    setTypedExpression("");
    setInputtedExpressions([]);
    setSelectedLevels({});
  }

  return (
    <CustomCard margin="10px 0" padding="10px 30px" backgroundColor="lighterGrey" display={showFilters ? "" : "none"}>
      <S.UpperContainer>
        <SearchInput
          inputtedExpressions={inputtedExpressions}
          setInputtedExpressions={setInputtedExpressions}
          typedExpression={typedExpression}
          setTypedExpression={setTypedExpression}
        />
        <Button
          label={t("clearFilters")}
          type="outlined"
          icon="x"
          position="left"
          onClick={resetFilters}
          disabled={!isAnyFilterApplied()}
        />
      </S.UpperContainer>
      <Text size="sm" weight="bold" className="mb-2">
        {t("filterByHierarchy")}
      </Text>
      <div className="d-flex">
        {hierarchy.levels.map((level, index) => (
          <HierarchyFilterBox
            productIds={productIds}
            level={level}
            selectedProductIds={selectedProductIds}
            selectedLevels={selectedLevels}
            setSelectedLevels={setSelectedLevels}
            key={index}
          />
        ))}
      </div>
    </CustomCard>
  );
}
