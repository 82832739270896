import React from "react";
import { Text } from "@aidron/aidron-ds";

export default function BodyCell({ prediction, format }) {
  const language = localStorage.getItem("i18nextLng");
  const prefix = format === "items" ? "" : "R$ ";
  return (
    <Text className={format === "items" ? "text-center" : ""}>
      {!isNaN(prediction) && prediction !== null ? prefix + Math.ceil(prediction).toLocaleString(language) : "N/A"}
    </Text>
  );
}
