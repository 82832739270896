import React from "react";
import { Text } from "@aidron/aidron-ds";
import { i18n } from "../../../../../translate/i18n";

import * as S from "./ProductIndexCard.style";

import CustomHeading from "../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

function ProductIndexCard({ index }) {
  const { t } = i18n;
  const { label, value } = index;

  return (
    <S.CustomContainer>
      <S.TitleContainer>
        <Text weight="bold" size="xs">
          {label}
        </Text>
      </S.TitleContainer>
      <S.NumberContainer>
        {/\d/.test(value) ? (
          <CustomHeading size="md" color="orange">
            {value}
          </CustomHeading>
        ) : (
          <CustomText color="orange" weight="bold">
            {t("comingSoon")}
          </CustomText>
        )}
      </S.NumberContainer>
    </S.CustomContainer>
  );
}

export default ProductIndexCard;
