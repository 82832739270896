import React from "react";
import { Text } from "@aidron/aidron-ds";

import { toNDecimalsString } from "../../utils/utils";
import { i18n } from "../../translate/i18n";

import * as S from "./ClientFlowSection.style";

import CustomText from "../StyledComponents/CustomText/CustomText.style";
import IconButtonWithModal from "../IconButtonWithModal/IconButtonWithModal";
import CustomHeading from "../StyledComponents/CustomHeading/CustomHeading.style";

export default function ClientFlowSection({ clientFlow, numberSize }) {
  const { t } = i18n;
  return clientFlow ? (
    <S.OuterContainer>
      <S.InnerContainer>
        <S.SubtitleContainer>
          <CustomText weight="bold" size="15px">
            {t("flowDependencyForSelling")}
          </CustomText>
          <IconButtonWithModal
            icon="help-circle"
            modalHeading={`${t("help")}: ${t("flowDependencyForSelling")}`}
            renderModalContent={() => <Text>{t("flowDependencyForSellingExplanation")}</Text>}
          />
        </S.SubtitleContainer>
        <CustomHeading size={numberSize ? numberSize : "lg"} color="orange">
          {toNDecimalsString(clientFlow, 0)}%
        </CustomHeading>
      </S.InnerContainer>
    </S.OuterContainer>
  ) : (
    <></>
  );
}
