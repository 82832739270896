import React from "react";
import { Card, Heading } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

export default function ResultError() {
  const { t } = i18n;
  return (
    <Card>
      <Heading size="sm">{t("searchError")}</Heading>
    </Card>
  );
}
