import * as S from "./opportunityTypes.style";

import { i18n } from "../../translate/i18n";

const { t } = i18n;

export const typeDetails = new Map([
  [
    "additionalSale",
    {
      title: t("additionalSale"),
      summary: t("additionalSaleExplanation"),
      percentLabel: t("expectedIncreaseInSales"),
      icon: <S.SaleIcon />,
    },
  ],
  [
    "avoidLoss",
    {
      title: t("avoidSalesLoss"),
      summary: t("avoidSalesLossExplanation"),
      percentLabel: t("expectedIncreaseInSales"),
      icon: <S.SaleIcon />,
    },
  ],
  [
    "stock",
    {
      title: t("optimizePurchase"),
      summary: t("optimizePurchaseExplanation"),
      percentLabel: t("futurePurchaseReduction"),
      icon: <S.StockIcon />,
    },
  ],
]);
