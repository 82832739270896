import React from "react";
import { toNDecimalsString } from "../../../../../../../../utils/utils";
import { i18n } from "../../../../../../../../translate/i18n";

import * as S from "./AdjustDriversChartSeries.style";

import CustomText from "../../../../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function AdjustDriversChartSeries({ series }) {
  const { t } = i18n;
  const { driver, value } = series;

  return (
    <S.Grid>
      <S.TextContainer>
        <CustomText textAlign="end" size="xs" margin="0">
          {t(driver)}
        </CustomText>
      </S.TextContainer>
      <S.OuterBar>
        <S.InnerBar width={value * 1.5} />
      </S.OuterBar>
      <CustomText color="orange" weight="bold" size="14px !important">
        {toNDecimalsString(value, 0)}%
      </CustomText>
    </S.Grid>
  );
}
