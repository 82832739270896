import React, { useState } from "react";
import { Button } from "@aidron/aidron-ds";
import { useLocation, useHistory } from "react-router-dom";
import { i18n } from "../../../translate/i18n";

import * as S from "./OpportunityOptions.style";

import HierarchyFilters from "../../../components/NewTableOptions/HierarchyFilters/HierarchyFilters";
import SortSelect from "../../../components/SortSelect/SortSelect";
import ArrowModalButton from "../../../components/ArrowModalButton/ArrowModalButton";
import OpportunityTypeFilter from "./OpportunityTypeFilter/OpportunityTypeFilter";
import FilterButton from "../../../components/NewTableOptions/HierarchyFilters/FilterButton/FilterButton";
import CustomCard from "../../../components/StyledComponents/CustomCard/CustomCard.style";

export default function OpportunityOptions({
  productIds,
  selectedProductIds,
  setSelectedProductIds,
  order,
  setOrder,
  selectedTypes,
  setSelectedTypes,
  typeOptions,
}) {
  const { t } = i18n;

  const [showFilters, setShowFilters] = useState(false);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [selectedLevels, setSelectedLevels] = useState({});
  const [inputtedExpressions, setInputtedExpressions] = useState([]);

  const orderOptions = [
    {
      value: "descending",
      displayName: t("highestValue"),
    },
    {
      value: "ascending",
      displayName: t("lowestValue"),
    },
  ];

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlProductId = params.get("productId");
  const history = useHistory();

  function onShowAllOpportunities() {
    params.delete("productId");
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
    setSelectedProductIds(productIds);
  }

  return (
    <>
      <CustomCard margin="0 0 15px 0">
        <S.OuterContainer>
          {urlProductId ? (
            <Button label={t("showAllOpportunities")} onClick={onShowAllOpportunities} size="lg" />
          ) : (
            <FilterButton showFilters={showFilters} setShowFilters={setShowFilters} appliedFiltersCount={appliedFiltersCount} />
          )}
          <S.InnerContainer>
            <ArrowModalButton
              label={t("opportunityType")}
              size="lg"
              renderModalContent={({ closeModal }) => (
                <OpportunityTypeFilter
                  selectedTypes={selectedTypes}
                  setSelectedTypes={setSelectedTypes}
                  closeModal={closeModal}
                  typeOptions={typeOptions}
                />
              )}
            />
            <SortSelect label={`${t("orderBy")}:`} selected={order} options={orderOptions} onChange={setOrder} />
          </S.InnerContainer>
        </S.OuterContainer>
      </CustomCard>

      <HierarchyFilters
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        setAppliedFiltersCount={setAppliedFiltersCount}
        showFilters={showFilters}
        selectedLevels={selectedLevels}
        setSelectedLevels={setSelectedLevels}
        inputtedExpressions={inputtedExpressions}
        setInputtedExpressions={setInputtedExpressions}
      />
    </>
  );
}
