import React, { useState } from "react";
import { Button } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";
import { typeDetails } from "../../../../utils/opportunityTypes/opportunityTypes";
import { i18n } from "../../../../translate/i18n";

import * as S from "./ListItem.style";

import ProductHierarchyHeading from "../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import TextAndLabelView from "../../../Opportunity/ProductListItems/ListItem/TextAndLabelView/TextAndLabelView";
import CustomCard from "../../../../components/StyledComponents/CustomCard/CustomCard.style";
import ActionModal from "../../../Opportunity/ProductListItems/ListItem/ActionModal/ActionModal";
import ModalTemplate from "../../../../components/ModalTemplate/ModalTemplate";

export default function ListItem({ opportunity, onItemClick, selectedId, fetchData }) {
  const { t } = i18n;

  const { id, productId, value, type1, status } = opportunity;

  const [openModal, setOpenModal] = useState(false);

  const opportunityDetails = typeDetails.get(type1);

  function onButtonClick(e) {
    e.stopPropagation();
    setOpenModal(true);
  }

  return (
    <>
      <CustomCard
        onClick={() => onItemClick(id)}
        cursor="pointer"
        margin="0 0 20px 0"
        backgroundColor={id === selectedId ? "transparentDarkOrange" : ""}
        greyBackgroundOnHover
      >
        <S.Container>
          <S.ItemImage border>
            {opportunityDetails.icon}
            <ProductHierarchyHeading productId={productId} useMockedStores />
          </S.ItemImage>

          <S.Item textAlign border>
            <TextAndLabelView label={t("opportunity")} text={opportunityDetails.title} />
          </S.Item>

          <S.Item textAlign>
            <TextAndLabelView label={t("opportunitySize")} heading={`R$ ${toNDecimalsString(value, 0)}`} />
          </S.Item>

          <S.Item alignItems="end" cursor="default">
            <Button icon="x" type="outlined" onClick={e => onButtonClick(e)} />
          </S.Item>
        </S.Container>
      </CustomCard>

      {openModal && (
        <ModalTemplate width="650px" closingFunction={() => setOpenModal(false)} heading=" ">
          <ActionModal onClose={() => setOpenModal(false)} opportunityId={id} fetchData={fetchData} status={status} />
        </ModalTemplate>
      )}
    </>
  );
}
