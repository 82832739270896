import React from "react";
import { Button } from "@aidron/aidron-ds";
import { i18n } from "../../../../../translate/i18n";

import * as S from "./ReactivateModal.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function ReactivateModal({ closeModal, reactivateOpportunity }) {
  const { t } = i18n;

  function onConcludedClick() {
    reactivateOpportunity();
    closeModal();
  }

  return (
    <>
      <CustomText margin="0 0 1.5rem 0">{t("willBeListedAsOpportunity")}</CustomText>
      <S.ButtonsContainer>
        <Button label="Cancelar" type="outlined" onClick={closeModal} />
        <Button label="Ok" onClick={onConcludedClick} />
      </S.ButtonsContainer>
    </>
  );
}
