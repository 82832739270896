import React from "react";
import { Text } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";

import * as S from "./Error.style";

export default function Error() {
  const { t } = i18n;
  return (
    <Text weight="bold">
      {t("noOpportunitiesGoTo")} <S.CustomLink to="/oportunidades/produtos">{t("opportunitiesPage")}.</S.CustomLink>
    </Text>
  );
}
