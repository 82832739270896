import React, { useState } from "react";
import { Button } from "@aidron/aidron-ds";
import { i18n } from "../../../../translate/i18n";

import { updateOpportunities } from "../../../../services/OpportunityService";

import * as S from "./RemovedModal.style";

import ContentLoader from "../../../../components/ContentLoader/ContentLoader";
import ProductListItems from "../../ProductListItems/ProductListItems";
import ModalTemplate from "../../../../components/ModalTemplate/ModalTemplate";
import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function RemovedModal({ closeModal, fetchData, opportunities }) {
  const { t } = i18n;

  const [confirmationMessage, setConfirmationMessage] = useState("");

  const token = localStorage.getItem("token");

  async function reactivateOpportunity(id) {
    const updatedOpportunity = { id: id, status: "reactivated", reactivatedDate: new Date() };
    const result = await updateOpportunities(token, [updatedOpportunity]);

    fetchData();

    if (result.status === 200) {
      setConfirmationMessage(t("opportunityWasReopened"));
    } else {
      setConfirmationMessage(t("errorContactAdmin"));
    }
  }

  function onConfirmationModalClose() {
    setConfirmationMessage("");
    if (!opportunities.length) {
      closeModal();
    }
  }

  return (
    <ModalTemplate width="90%" closingFunction={closeModal} heading={t("removedOpportunities")}>
      <ContentLoader
        resultCondition={opportunities?.length}
        contentCondition={opportunities.length}
        renderError={() => <CustomHeading size="xs">{t("noRemovedOpportunities")}</CustomHeading>}
      >
        <ProductListItems opportunities={opportunities} fetchData={fetchData} reactivateOpportunity={reactivateOpportunity} />
      </ContentLoader>
      <S.ButtonWrapper>
        <Button label={t("done")} onClick={closeModal} />
      </S.ButtonWrapper>

      {confirmationMessage && (
        <ModalTemplate width="300px" closingFunction={onConfirmationModalClose} heading=" ">
          <CustomHeading size="sm" margin="1.5rem 0 0 0" textAlign="center">
            {confirmationMessage}
          </CustomHeading>
          <S.ConfirmationButtonContainer>
            <Button label="Ok" onClick={onConfirmationModalClose} />
          </S.ConfirmationButtonContainer>
        </ModalTemplate>
      )}
    </ModalTemplate>
  );
}
