import React from "react";
import { Text, Button } from "@aidron/aidron-ds";
import { getNextLevel } from "../../../../utils/utils";
import { i18n } from "../../../../translate/i18n";

import * as S from "./MainIndexes.style";

import CustomCard from "../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import SumCard from "./SumCard/SumCard";
import MultiSlideCarousel from "../../../PlanVsForecast/ConsolidatedView/SecondaryChartsSection/SecondaryCharts/MultiSlideCarousel/MultiSlideCarousel";
import HierarchySumBox from "./HierarchySumBox/HierarchySumBox";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";

export default function MainIndexes({ selectedGroup, defineProductFilter, selectedLevels, products, predictions }) {
  const { t } = i18n;

  const selectedLevelNumber = selectedGroup.level[selectedGroup.level.length - 1];
  const nextLevel = `level${Number(selectedLevelNumber) + 1}`;
  const nextLevelGroups = getNextLevel(selectedGroup, nextLevel, products);

  const filteredPredictions = predictions.filter(prediction => selectedGroup.productIds.includes(prediction.productId));

  const clientPlanSum = filteredPredictions.reduce((acc, curr) => {
    if (curr.clientPrediction) return acc + Number(curr.clientPrediction);
    else return acc;
  }, 0);

  const newPlanSum = filteredPredictions.reduce((acc, curr) => {
    const property = curr.editedNewPlan ? "editedNewPlan" : "newPlan";
    if (curr[property]) return acc + Number(curr[property]);
    else return acc;
  }, 0);

  const difference = clientPlanSum - newPlanSum;

  function isButtonClicked(groupName) {
    return selectedLevels[nextLevel] && selectedLevels[nextLevel].includes(groupName);
  }

  function handleButtonClick(groupName) {
    if (isButtonClicked(groupName)) {
      defineProductFilter({});
    } else {
      return defineProductFilter({ [nextLevel]: [groupName] });
    }
  }

  function orderByDifference() {
    const groupsWithIndexes = nextLevelGroups.map(group => calculateIndexes(group));
    return groupsWithIndexes.sort((a, b) => Math.abs(b.difference) - Math.abs(a.difference));
  }

  function calculateIndexes(group) {
    const groupPredictions = filteredPredictions.filter(prediction => group.productIds.includes(prediction.productId));

    const clientPlanSum = groupPredictions.reduce((acc, curr) => {
      if (curr.clientPrediction) return acc + Number(curr.clientPrediction);
      else return acc;
    }, 0);

    const newPlanSum = groupPredictions.reduce((acc, curr) => {
      const property = curr.editedNewPlan ? "editedNewPlan" : "newPlan";
      if (curr[property]) return acc + Number(curr[property]);
      else return acc;
    }, 0);

    const difference = clientPlanSum - newPlanSum;
    return { ...group, clientPlanSum, newPlanSum, difference };
  }

  return (
    <S.OuterContainer>
      <S.CardsContainer>
        <SumCard icon="file-text" number={clientPlanSum} numberColor="mediumGrey" label={t("currentPlan")} />
        <SumCard icon="target" number={newPlanSum} numberColor="orange" label={t("newPlan")} />
        <SumCard
          icon="dollar-sign"
          number={difference}
          numberColor="mediumGrey"
          label={t("differenceFromPlan")}
          prefix={difference > 0 ? "-" : "+"}
        />
      </S.CardsContainer>

      <CustomCard border="unset" padding="5px 16px 5px 150px">
        <CustomHeading size="xs" color="mediumGrey" margin="0 0 5px 0" textAlign="center">
          {t("total")} {selectedGroup.displayName}
        </CustomHeading>

        <ContentLoader
          resultCondition={selectedGroup}
          contentCondition={filteredPredictions.length}
          renderError={() => <Text>{t("errorContactAdmin")}</Text>}
        >
          <MultiSlideCarousel slideCount={nextLevelGroups.length} key={nextLevelGroups}>
            {orderByDifference(nextLevelGroups).map((group, index) => (
              <S.Box>
                <HierarchySumBox group={group} key={index} boxCount={nextLevelGroups.length} />
                <S.ButtonContainer>
                  <Button
                    label={isButtonClicked(group.name) ? t("clearFilters") : t("filter")}
                    onClick={() => handleButtonClick(group.name)}
                    type={isButtonClicked(group.name) ? "filled" : "outlined"}
                  />
                </S.ButtonContainer>
              </S.Box>
            ))}
          </MultiSlideCarousel>
        </ContentLoader>
      </CustomCard>
    </S.OuterContainer>
  );
}
