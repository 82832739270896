import React from "react";
import { Card } from "@aidron/aidron-ds";

import * as S from "./UpperCardModel.style";

import OuterCardHeading from "../OuterCardHeading/OuterCardHeading";
import InnerCard from "./InnerCard/InnerCard";

export default function UpperCardModel({ title, text, leftCardData, rightCardData }) {
  return (
    <S.Wrapper>
      <Card style={{ height: "100%" }}>
        <OuterCardHeading title={title.toUpperCase()} text={text} />
        <S.InnerCardsWrapper>
          <InnerCard title={leftCardData.title} data={leftCardData.data} />
          <InnerCard title={rightCardData.title} data={rightCardData.data} />
        </S.InnerCardsWrapper>
      </Card>
    </S.Wrapper>
  );
}
