import { Button } from "@aidron/aidron-ds";

import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ sixthColumn }) => (sixthColumn ? "30% 1fr 0.7fr 1fr 0.5fr 0.5fr" : "30% repeat(3, 1fr) 10%")};
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Item = styled.div`
  padding: 0 20px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LastColumnsItems = styled.div`
  display: flex;
`;

const ItemImage = styled.div`
  padding: 0 10px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  align-items: center;
`;

const ButtonAccordion = styled(Button)`
  position: absolute;
  bottom: -10px;
  left: 41%;
`;

export { Container, Item, LastColumnsItems, ItemImage, ButtonAccordion };
