import React, { useState } from "react";
import { Card, Text, TextField, Button, Dialog } from "@aidron/aidron-ds";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { i18n } from "../../translate/i18n";
import { getMonthName } from "../../utils/utils";

import "./calendarPage.css";

import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function CalendarPage({ setShowCalendar }) {
  const { t } = i18n;

  const [year, setYear] = useState(new Date().getFullYear());
  const [warningText, setWarningText] = useState(false);
  const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <Dialog open overlay width="80%">
      <div className="d-flex justify-content-end">
        <Button label="" onClick={() => setShowCalendar(false)} type="ghost" icon="x" className="close-modal-button" />
      </div>
      <div className="d-flex justify-content-center my-2">
        <div className="d-flex justify-content-between width-90percent">
          <PageHeading>
            {t("commercialCalendar")} {year}
          </PageHeading>
          <div className="year-input-height me-3">
            <TextField
              onChange={e => {
                if (e.length === 4 && /^[0-9]*$/.test(e)) {
                  setYear(e);
                  setWarningText(false);
                } else setWarningText(true);
              }}
              value={year}
              label={t("typeYear")}
              placeholder=""
            />
            {warningText && <Text className="filter-warning">{t("insertYear")}</Text>}
          </div>
        </div>
      </div>
      <div className="calendar-page">
        <div className="d-flex flex-wrap justify-content-center">
          {monthNumbers.map((num, index) => (
            <div className="m-4" key={index}>
              <Card
                style={{
                  padding: "0",
                  height: "100%",
                  borderRadius: "10px",
                }}
              >
                <div className="month-name-div d-flex justify-content-center mb-3">
                  <Text weight="bold">
                    {getMonthName(num)} {year}
                  </Text>
                </div>
                <Calendar
                  showWeekNumbers
                  showNeighboringMonth={false}
                  defaultActiveStartDate={new Date(year, num - 1, 1)}
                  showNavigation={false}
                  key={year}
                />
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
}
