import React from "react";
import { i18n } from "../../../translate/i18n";

import * as S from "./AdjustHeader.style";

import FiltersAndSums from "../AdjustSummary/FiltersAndSums/FiltersAndSums";
import CustomHeading from "../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function AdjustHeader({
  products,
  selectedGroup,
  setSelectedGroupValue,
  filteredPredictions,
  originalMonthColumns,
  selectedMonthsValue,
  setSelectedMonthsValue,
}) {
  const { t } = i18n;
  return (
    <S.Container>
      <CustomHeading size="lg" margin="0">
        {t("adjustSalesPlan")}
      </CustomHeading>

      <FiltersAndSums
        products={products}
        selectedGroup={selectedGroup}
        setSelectedGroupValue={setSelectedGroupValue}
        filteredPredictions={filteredPredictions}
        originalMonthColumns={originalMonthColumns}
        selectedMonthsValue={selectedMonthsValue}
        setSelectedMonthsValue={setSelectedMonthsValue}
      />
    </S.Container>
  );
}
