import React, { useState } from "react";
import { Button, Radio, RadioOption, Text } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";

import ModalTemplate from "../../ModalTemplate/ModalTemplate";

export default function ActionRadio({ onClose }) {
  const { t } = i18n;

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const options = [t("opportunityWillBeAnalysed"), t("notAnOpportunity")];

  const confirmationModalMessage =
    selectedOption === t("opportunityWillBeAnalysed") ? t("opportunityAddedToCheckout") : t("opportunitySuccessfullyRemoved");

  function onConcludedClick() {
    if (selectedOption === "") {
      onClose();
    } else {
      setOpenConfirmationModal(true);
    }
  }

  function onConfirmationModalClose() {
    setSelectedOption("");
    setOpenConfirmationModal(false);
    onClose();
  }

  return (
    <>
      <div className="ms-4">
        <Radio
          label=""
          selected={""}
          handleChange={e => {
            setSelectedOption(e);
            setOpenConfirmationModal(false);
          }}
          className="d-flex flex-column"
        >
          {options.map((option, index) => (
            <RadioOption value={option} key={index}>
              <Text>{option}</Text>
            </RadioOption>
          ))}
        </Radio>
      </div>
      {selectedOption === t("notAnOpportunity") && (
        <>
          <Text>{t("describeReason")}</Text>
          <textarea id="paragraph" name="paragraph" rows="10" cols="50" />
        </>
      )}
      <div className="d-flex justify-content-end">
        <Button label="Finalizar" onClick={onConcludedClick} />
      </div>
      {openConfirmationModal && selectedOption !== "" && (
        <ModalTemplate width="300px" closingFunction={onConfirmationModalClose} heading=" ">
          <Text className="mt-4">{confirmationModalMessage}</Text>
          <div className="d-flex justify-content-center mt-5">
            <Button label="Ok" onClick={onConfirmationModalClose} />
          </div>
        </ModalTemplate>
      )}
    </>
  );
}
