import React from "react";
import CustomText from "../../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function Cell({ prediction, format, color, adjustmentColumn }) {
  const prefix = format === "items" ? "" : "R$ ";
  const language = localStorage.getItem("i18nextLng");
  return (
    <CustomText className={format === "items" ? "text-center" : ""} color={color(prediction)} weight="bold">
      {!isNaN(prediction) && prediction !== null
        ? `${prediction < 0 ? "-" : adjustmentColumn && prediction > 0 ? "+" : ""} ${prefix} ${Math.ceil(
            Math.abs(prediction)
          ).toLocaleString(language)}`
        : " "}
    </CustomText>
  );
}
