import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../templates/context/CompanyContext";
import { weeksInYear } from "../../utils/utils";

import { getPredictions } from "../../services/StorePredictionsService";
import { getForecasts } from "../../services/ForecastService";

import Default from "../../components/Default/Default";
// import TableDownloadButton from "../../components/TableDownloadButton/TableDownloadButton";
import NewTableOptions from "../../components/NewTableOptions/NewTableOptions";
import WeekBlocksColumnFilter from "./WeekBlocksColumnFilter/WeekBlocksColumnFilter";
import PaginationButtons from "../../components/PaginationButtons/PaginationButtons";
import ChannelAccuracyTable from "./ChannelAccuracyTable/ChannelAccuracyTable";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function ChannelAccuracy() {
  const { hierarchy } = useContext(CompanyContext);

  const [predictionData, setPredictionData] = useState([]);
  const [freezeColumns, setFreezeColumns] = useState(true);
  const [sortBy, setSortBy] = useState({});
  const [selectedHistoricWeekIds, setSelectedHistoricWeekIds] = useState([]);
  const [selectedHierarchyIndexes, setSelectedHierarchyIndexes] = useState(hierarchy.levels.map((level, index) => index));
  const [weekBlocks, setWeekBlocks] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [storesResults, setStoresResults] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const resultsPerPage = 10;

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchPredictionData();
  }, [selectedHistoricWeekIds, currentPage, sortBy]);

  async function fetchInitialData() {
    const token = localStorage.getItem("token");
    const forecasts = await getForecasts(token, "storePredictions");

    const initialWeekBlocks = defineWeekBlocks(forecasts);
    setWeekBlocks(initialWeekBlocks);
    const lastForecast = initialWeekBlocks[initialWeekBlocks.length - 1];

    const weekBlockToSortBy = initialWeekBlocks.find(block => block.forecastId === lastForecast.forecastId);

    const initialSortBy = {
      columnLabel: "realizedItems",
      order: "descending",
      orderForecastId: weekBlockToSortBy.forecastId,
    };
    setSortBy(initialSortBy);

    const firstForecastsIds = initialWeekBlocks.map(forecast => forecast.forecastId);
    const initialHistoricWeekIds = firstForecastsIds.slice(-4);
    setSelectedHistoricWeekIds(initialHistoricWeekIds);
  }

  function defineWeekBlocks(forecasts) {
    const years = forecasts.map(forecast => forecast.year);
    const uniqueYears = [...new Set(years)].sort((a, b) => a - b);

    const weeksAndYearsArray = uniqueYears.map(year => {
      const weeksFromYear = weeksInYear(year);
      const weeksArray = Array.from({ length: weeksFromYear }, (_, i) => i + 1);
      return weeksArray.map(week => {
        return { week: week, year: year };
      });
    });

    const allWeekBlocks = [];

    weeksAndYearsArray.flat().forEach((week, index, arr) => {
      if (week.week === 1 || week.week % 4 === 1) {
        const blockForecast = forecasts.find(forecast => forecast.week === week.week && forecast.year === week.year);

        if (blockForecast) {
          const weekBlockObject = {
            weeks: [week.week, arr[index + 1].week, arr[index + 2].week, arr[index + 3].week],
            year: week.year,
            forecastId: blockForecast.forecastId,
          };
          allWeekBlocks.push(weekBlockObject);
        }
      }
    });
    allWeekBlocks.pop();
    return allWeekBlocks;
  }

  async function fetchPredictionData() {
    if (selectedHistoricWeekIds.length > 0) {
      const token = localStorage.getItem("token");

      const predictions = await getPredictions(
        token,
        selectedHistoricWeekIds,
        selectedProductIds,
        sortBy,
        currentPage,
        resultsPerPage
      );

      setPredictionData(predictions.results);
      setStoresResults(predictions.storeIndexes);
      if (productIds.length === 0) {
        setProductIds(predictions.productIds);
        setSelectedProductIds(predictions.productIds);
      }
      if (!pagination.lastPage) {
        setPagination({ lastPage: predictions.lastPage });
      }
    }
  }

  return (
    <Default>
      <PageHeading>Analisar Assertividade Canal</PageHeading>
      {/* <div className="d-flex justify-content-end mb-3 box-shadow-unset-on-select background-color-unset-on-select padding-left-0-on-select">
        <TableDownloadButton fileName="Lojas" />
      </div> */}
      <NewTableOptions
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
        freezeColumns={freezeColumns}
        setFreezeColumns={setFreezeColumns}
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        renderHistoricFilter={({ closeModal }) => (
          <WeekBlocksColumnFilter
            weekBlocks={weekBlocks}
            onConcludedClick={closeModal}
            setSelectedHistoricWeekIds={setSelectedHistoricWeekIds}
            selectedHistoricWeekIds={selectedHistoricWeekIds}
          />
        )}
      />
      <ChannelAccuracyTable
        freezeColumns={freezeColumns}
        sortRowsBy={sortBy}
        setSortRowsBy={setSortBy}
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        predictionData={predictionData}
        selectedProductIds={selectedProductIds}
        currentPage={currentPage}
        selectedHistoricWeekIds={selectedHistoricWeekIds}
        weekBlocks={weekBlocks}
        storesResults={storesResults}
      />
      <PaginationButtons currentPage={currentPage} setCurrentPage={setCurrentPage} pagination={pagination} />
    </Default>
  );
}
