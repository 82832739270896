import React, { useState } from "react";
import { Menu, Dropdown, MenuItem, Icon } from "@aidron/aidron-ds";

import * as S from "./LanguageSelector.style";

export default function LanguageSelector({ iconColor }) {
  const [open, setOpen] = useState(false);

  const I18N_STORAGE_KEY = "i18nextLng";

  function changeLanguage(languageCode) {
    localStorage.setItem(I18N_STORAGE_KEY, languageCode);
    window.location.reload(false);
    setOpen(!open);
  }

  return (
    <div className="language-selector">
      <Menu>
        <S.StyledButton
          icon="chevron-down"
          label=""
          onClick={() => {
            setOpen(!open);
          }}
          position="right"
          color={iconColor ? iconColor : ""}
        >
          <Icon icon="globe" size="30" />
        </S.StyledButton>
        <Dropdown position="bottom" open={open} setOpen={setOpen}>
          <MenuItem onClick={() => changeLanguage("pt-BR")}>Português</MenuItem>
          <MenuItem onClick={() => changeLanguage("en-US")}>English</MenuItem>
        </Dropdown>
      </Menu>
    </div>
  );
}
